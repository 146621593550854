//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                  from 'immutability-helper';
import { MessageOverlayTypes } from '@/store/actions/messageOverlay';

const initialState = {
    messageOverlayOpen: false,
    title:              null,
    text:               null,
    hint:               null,
};

const closeOverlay = (action, state) => {
    return update(state, {
        messageOverlayOpen: {
            $set: false,
        },
    });
};

const openOverlay = (action, state) => {
    return update(state, {
        messageOverlayOpen: {
            $set: true,
        },
    });
};

const setTextAndTitle = (action, state) => {
    return update(state, {
        text:  {
            $set: action.text,
        },
        title: {
            $set: action.title,
        },
        hint:  {
            $set: action.hint,
        },
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case MessageOverlayTypes.CLOSE_OVERLAY:      return closeOverlay(action, state);
        case MessageOverlayTypes.OPEN_OVERLAY:       return openOverlay(action, state);
        case MessageOverlayTypes.SET_TEXT_AND_TITLE: return setTextAndTitle(action, state);
        default:                                     return state;
        // @formatter:on
    }
}
