//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes   from 'prop-types';
import _                from 'lodash';
import ComponentHelper  from '@/helper/ComponentHelper';
import Device           from '@/helper/Device';
import FileUploadHelper from '@/helper/FileUpload';
import React            from 'react';
import styles           from './styles.module.scss';

class FileUpload extends React.Component {
    constructor(props) {
        super(props);

        this.inputField = React.createRef();
    }

    onLoadingFilesComplete = (files) => {
        this.props.onLoadingFilesComplete(files);
    };

    addFiles = async (event) => {
        const uploadedFiles = event.target.files;
        const addedFiles    = await FileUploadHelper.addFiles(uploadedFiles);

        this.onLoadingFilesComplete(addedFiles);
    };

    getAcceptedFileTypes = () => {
        const acceptedFileTypes = [
            'application/pdf',
            'application/zip',
        ];

        return acceptedFileTypes.join(', ');
    };

    render() {
        return (
            <input
                ref={this.inputField}
                className={styles.fileInput}
                type={'file'}
                onChange={this.addFiles}
                multiple
                accept={this.getAcceptedFileTypes()}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    componentDidMount() {
        this.openFileSelect();
    };

    componentDidUpdate() {
        this.openFileSelect();
    };

    openFileSelect = () => {
        const isTouchDevice = Device.isTouchDevice();

        if (isTouchDevice && this.props.dropInOpen) {
            this.inputField.current.click();
        }
    };
}

const Component = FileUpload;

Component.propTypes = {
    dropInOpen:             PropTypes.bool,
    onLoadingFilesComplete: PropTypes.func,
};

Component.defaultProps = {
    dropInOpen:             false,
    onLoadingFilesComplete: _.noop,
};

Component.renderAffectingProps = [
    'dropInOpen',
];

Component.renderAffectingStates = [];

export default Component;
