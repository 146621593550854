//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import dropInReducer         from './dropIn';
import loadingOverlayReducer from './loadingOverlay';
import messageOverlayReducer from './messageOverlay';
import confirmOverlayReducer from './confirmOverlay';
import setReducer            from './set';
import userReducer           from './user';
import { combineReducers }   from 'redux';
import { connectRouter }     from 'connected-react-router';

export default (history) => combineReducers({
    dropIn:         dropInReducer,
    loadingOverlay: loadingOverlayReducer,
    messageOverlay: messageOverlayReducer,
    confirmOverlay: confirmOverlayReducer,
    set:            setReducer,
    user:           userReducer,
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router: connectRouter(history),
})
