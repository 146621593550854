//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import classNames      from 'classnames';
import ComponentHelper from '@/helper/ComponentHelper';
import Link            from '../Link';
import PropTypes       from '@/components/PropTypes';
import React           from 'react';
import ButtonTheme     from '@/components/stateless/atomic/Button/ButtonTheme';
import styles          from './styles.module.scss';

class Button extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    getClassName = () => {
        const className = classNames(
            styles.button,
            {
                [styles.red]:         this.props.theme === ButtonTheme.red,
                [styles.transparent]: this.props.theme === ButtonTheme.transparent,
            },
        );

        return className;
    };

    render () {
        const finalClassName = this.getClassName();
        const text           = this.props.text;

        if (this.props.onClick) {
            return (
                <button
                    className={finalClassName}
                    onClick={this.props.onClick}
                >
                    {
                        text ? (
                            <span className={styles.label}>
                                {text}
                            </span>
                        ) : null
                    }
                </button>
            );
        } else if (this.props.to) {
            return (
                <Link
                    className={finalClassName}
                    to={this.props.to}
                >
                    {
                        text ? (
                            <span className={styles.label}>
                                {text}
                            </span>
                        ) : null
                    }
                </Link>
            );
        }

        return null;
    }
}

const Component = Button;

Component.propTypes = {
    onClick: PropTypes.func,
    text:    PropTypes.string,
    theme:   PropTypes.oneOf(Object.values(ButtonTheme)),
    to:      PropTypes.string,
};

Component.defaultProps = {
    onClick: null,
    text:    '',
    theme:   ButtonTheme.default,
    to:      null,
};

Component.renderAffectingProps = [
    'text',
    'theme',
    'to',
];

Component.renderAffectingStates = [];

export default Component;
