//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import classNames from 'classnames';
import PropTypes  from '@/components/PropTypes';
import React      from 'react';
import styles     from './styles.module.scss';

class Component extends React.Component {
    render () {
        return (
            <div
                className={classNames(
                    styles.textContainer,
                    (
                        this.props.autoStyleChildren ?
                            styles.textContainerAutoStyleChildren :
                            null
                    ),
                )}
            >
                {this.props.children}
            </div>
        );
    }
}

Component.propTypes = {
    autoStyleChildren: PropTypes.bool,
    children:          PropTypes.children,
};

Component.defaultProps = {
    autoStyleChildren: false,
    children:          null,
};

export default Component;
