//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import classNames      from 'classnames';
import ComponentHelper from '@/helper/ComponentHelper';
import Icon            from '../Icon';
import IconButtonSize  from '@/components/stateless/atomic/IconButton/IconButtonSize';
import IconButtonTheme from './IconButtonTheme';
import IconType        from '../Icon/IconType';
import Link            from '../Link';
import PropTypes       from '@/components/PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class IconButton extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    getClassName = () => {
        const className = classNames(
            styles.iconButton,
            {
                [styles.red]:               this.props.theme === IconButtonTheme.red,
                [styles.transparent]:       this.props.theme === IconButtonTheme.transparent,
                [styles.small]:             this.props.size === IconButtonSize.small,
                [styles.disabled]:          this.props.disabled && !this.props.disabledText,
                [styles.disabledNotHidden]: this.props.disabled && this.props.disabledText,
            },
        );

        return className;
    };

    getText = () => {
        if (!this.props.disabled) {
            return this.props.text;
        }

        if (this.props.disabled && this.props.disabledText) {
            return this.props.disabledText;
        }

        return this.props.text;
    };

    render () {
        const finalClassName = this.getClassName();
        const icon           = this.renderIcon();
        const text           = this.getText();

        if (this.props.onClick) {
            return (
                <button
                    className={finalClassName}
                    onClick={this.props.onClick}
                >
                    {icon}
                    {
                        text ? (
                            <span
                                className={classNames(
                                    styles.label,
                                    {
                                        [styles.withIcon]: icon,
                                    },
                                )}
                            >
                                {text}
                            </span>
                        ) : null
                    }
                </button>
            );
        } else if (this.props.to) {
            return (
                <Link
                    className={finalClassName}
                    to={this.props.to}
                >
                    {icon}
                    {
                        text ? (
                            <span
                                className={classNames(
                                    styles.label,
                                    {
                                        [styles.withIcon]: icon,
                                    },
                                )}
                            >
                                {text}
                            </span>
                        ) : null
                    }
                </Link>
            );
        }

        return null;
    }

    renderIcon = () => {
        if (this.props.iconType) {
            return (
                <Icon iconType={this.props.iconType} />
            );
        }

        return null;
    };
}

const Component = IconButton;

Component.propTypes = {
    disabled:     PropTypes.bool,
    disabledText: PropTypes.string,
    iconType:     PropTypes.oneOf(Object.values(IconType)),
    onClick:      PropTypes.func,
    size:         PropTypes.oneOf(Object.values(IconButtonSize)),
    text:         PropTypes.string,
    theme:        PropTypes.oneOf(Object.values(IconButtonTheme)),
    to:           PropTypes.string,
};

Component.defaultProps = {
    disabled:     false,
    disabledText: null,
    iconType:     null,
    onClick:      null,
    size:         IconButtonSize.default,
    text:         '',
    theme:        IconButtonTheme.default,
    to:           null,
};

Component.renderAffectingProps = [
    'disabled',
    'disabledText',
    'iconType',
    'text',
    'theme',
    'to',
];

Component.renderAffectingStates = [];

export default Component;
