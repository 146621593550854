//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const DropInTypes = {
    CLOSE_OVERLAY: 'DropIn/CLOSE_OVERLAY',
    OPEN_OVERLAY:  'DropIn/OPEN_OVERLAY',
};

const closeOverlay = makeActionCreator(
    DropInTypes.CLOSE_OVERLAY,
);

const openOverlay = makeActionCreator(
    DropInTypes.OPEN_OVERLAY,
);

export const DropInActions = {
    openOverlay,
    closeOverlay,
};
