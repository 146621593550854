//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React               from 'react';
import I18n                from 'i18next';
import styles              from './styles.module.scss';
import { withTranslation } from 'react-i18next';
import ComponentHelper     from '@/helper/ComponentHelper';

class Component extends React.Component {
    render () {
        return (
            <div
                aria-hidden={'true'}
                className={styles.screenTooSmallOverlay}
            >
                <div className={styles.screenTooSmallOverlayContent}>
                    <h2>
                        {I18n.t('screenTooSmallTitle')}
                    </h2>
                    <p>
                        {I18n.t('screenTooSmallText')}
                    </p>
                    <p>
                        {I18n.t('screenTooSmallHint')}
                    </p>
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default withTranslation()(Component);
