//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes         from 'prop-types';
import _                      from 'lodash';
import ComponentHelper        from '@/helper/ComponentHelper';
import React                  from 'react';
import StatelessSetHeader     from '@/components/stateless/atomic/SetHeader';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { DropInActions }      from '@/store/actions/dropIn';
import { SetActions }         from '@/store/actions/set';

class SetHeader extends React.Component {
    render () {
        return (
            <StatelessSetHeader
                setCount={this.props.setCount}
                openOverlayOnClick={this.onOpenOverlayClicked}
            />
        );
    }

    onOpenOverlayClicked = () => {
        this.props.openOverlay();
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SetHeader;

Component.propTypes = {
    setCount: PropTypes.number,
};

Component.defaultProps = {
    setCount: 0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    SetActions,
    DropInActions,
), dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(Component);
