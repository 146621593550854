//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const MessageOverlayTypes = {
    CLOSE_OVERLAY:      'MessageOverlay/CLOSE_OVERLAY',
    OPEN_OVERLAY:       'MessageOverlay/OPEN_OVERLAY',
    SET_TEXT_AND_TITLE: 'MessageOverlay/SET_TEXT_AND_TITLE',
};

const closeOverlay = makeActionCreator(
    MessageOverlayTypes.CLOSE_OVERLAY,
);

const openOverlay = makeActionCreator(
    MessageOverlayTypes.OPEN_OVERLAY,
);

const setTextAndTitle = makeActionCreator(
    MessageOverlayTypes.SET_TEXT_AND_TITLE,
    {
        text:  null,
        title: null,
        hint:  null,
    },
);

export const MessageOverlayActions = {
    openOverlay,
    closeOverlay,
    setTextAndTitle,
};
