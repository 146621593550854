//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes         from 'prop-types';
import _                      from 'lodash';
import ComponentHelper        from '@/helper/ComponentHelper';
import React                  from 'react';
import StatelessSetList       from '@/components/stateless/atomic/SetList';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { SetActions }         from '@/store/actions/set';

class SetList extends React.Component {
    render() {
        return (
            <StatelessSetList
                sets={this.props.sets}
                activeSetIndex={this.props.activeSetIndex}
                setOnClick={this.onSetClick}
            />
        );
    }

    onSetClick = (activeSetIndex) => {
        this.props.changeActiveSet(activeSetIndex);
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SetList;

Component.propTypes = {
    activeSetIndex:  PropTypes.number,
    changeActiveSet: PropTypes.func,
    sets:            PropTypes.array,
};

Component.defaultProps = {
    activeSetIndex:  null,
    changeActiveSet: _.noop,
    sets:            [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => {
    return {
        activeSetIndex: state.set.activeSetIndex,
        sets:           state.set.sets,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    SetActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
