//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

class Download {
    static downloadDataURI = (data, name) => {
        this.downloadFile(data, name);
    };

    static downloadFile = (data, name) => {
        console.log('Download: downloadFile', data);

        const url                = window.URL.createObjectURL(data);
        const element            = document.createElement('a');
        element.href             = url;
        element.download         = name;
        element.style.visibility = 'hidden';

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };
}

export default Download;
