//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import Button          from '@/components/stateless/atomic/Button';
import ButtonTheme     from '@/components/stateless/atomic/Button/ButtonTheme';
import classNames      from 'classnames';
import ComponentHelper from '@/helper/ComponentHelper';
import I18n            from 'i18next';
import PropTypes       from '@/components/PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import TooltipPosition from '@/components/stateless/atomic/Tooltip/TooltipPosition';

class Tooltip extends React.Component {
    render () {
        const position = this.getPosition();

        return (
            <div
                className={styles.tooltipContainer}
                style={position}
            >
                <div
                    className={classNames(
                        styles.tooltip,
                        {
                            [styles.tooltipPositionTop]:    this.props.position === TooltipPosition.top,
                            [styles.tooltipPositionLeft]:   this.props.position === TooltipPosition.left,
                            [styles.tooltipPositionRight]:  this.props.position === TooltipPosition.right,
                            [styles.tooltipPositionBottom]: this.props.position === TooltipPosition.bottom,
                        },
                    )}
                >
                    <p>
                        {this.props.text}
                    </p>
                    <Button
                        text={I18n.t('allRight')}
                        theme={ButtonTheme.red}
                        onClick={this.props.onClick}
                    />
                </div>
            </div>
        );
    }

    getPosition = () => {
        const position = {};

        if (this.props.top !== null) {
            position.top = this.props.top;
        }

        if (this.props.left !== null) {
            position.left = this.props.left;
        }

        if (this.props.right !== null) {
            position.right = this.props.right;
        }

        if (this.props.bottom !== null) {
            position.bottom = this.props.bottom;
        }

        return position;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Tooltip;

Component.propTypes = {
    bottom:     PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    identifier: PropTypes.string,
    left:       PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    onClick:    PropTypes.func,
    position:   PropTypes.oneOf(Object.values(TooltipPosition)),
    right:      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    text:       PropTypes.string,
    top:        PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

Component.defaultProps = {
    bottom:     null,
    identifier: '',
    left:       null,
    onClick:    _.noop,
    position:   TooltipPosition.left,
    right:      null,
    text:       '',
    top:        null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
