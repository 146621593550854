//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes         from 'prop-types';
import _                      from 'lodash';
import ComponentHelper        from '@/helper/ComponentHelper';
import React                  from 'react';
import StatelessTooltip       from '@/components/stateless/atomic/Tooltip';
import TooltipPosition        from '@/components/stateless/atomic/Tooltip/TooltipPosition';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { UserActions }        from '@/store/actions/user';

class Tooltip extends React.Component {
    render () {
        if (this.props.tooltips[this.props.identifier]) {
            return (
                <StatelessTooltip
                    bottom={this.props.bottom}
                    left={this.props.left}
                    position={this.props.position}
                    right={this.props.right}
                    text={this.props.text}
                    top={this.props.top}
                    identifier={this.props.identifier}
                    onClick={() => this.props.setTooltipHide({ identifier: this.props.identifier })}
                />
            );
        }

        return null;
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Tooltip;

Component.propTypes = {
    bottom:     PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    identifier: PropTypes.string,
    left:       PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    position:   PropTypes.oneOf(Object.values(TooltipPosition)),
    right:      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    text:       PropTypes.string,
    tooltips:   PropTypes.object,
    top:        PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

Component.defaultProps = {
    bottom:     null,
    identifier: '',
    left:       null,
    position:   TooltipPosition.left,
    right:      null,
    text:       '',
    tooltips:   {},
    top:        null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        tooltips: state.user.tooltips,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    UserActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
