//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import BreadCrumbBar   from '@/components/connected/BreadCrumbBar';
import ComponentHelper from '@/helper/ComponentHelper';
import HeaderButton    from '@/components/stateless/atomic/HeaderButton';
import I18n            from 'i18next';
import Logo            from '@/components/stateless/atomic/Logo';
import Routes          from '@/constants/Routes';
import styles          from './styles.module.scss';

class Header extends React.Component {
    render() {
        return (
            <div className={styles.headerContainer}>
                <div className={styles.logoContainer}>
                    <Logo />
                    <BreadCrumbBar />
                </div>
                <div className={styles.navigationContainer}>
                    <HeaderButton
                        label={I18n.t('imprint')}
                        route={Routes.imprint}
                    />
                    <HeaderButton
                        label={I18n.t('privacy')}
                        route={Routes.privacy}
                    />
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Header;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
