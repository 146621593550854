//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { PDFDocument } from 'pdf-lib';

class Pdf {
    static base64ToPdf = (loadedFiles) => {
        return Promise.all(loadedFiles.map((loadedFile) => {
            return PDFDocument.load(loadedFile.data).then((pdfDoc) => {
                return pdfDoc.save().then((data) => {
                    return {
                        data,
                        file: loadedFile,
                    };
                });
            }).catch(() => {
                console.error('Corrupt PDF file skipped');
            });
        }));
    };
}

export default Pdf;
