//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                      from 'lodash';
import ComponentHelper        from '@/helper/ComponentHelper';
import React                  from 'react';
import StatelessSidebarHeader from '@/components/stateless/atomic/SidebarHeader';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { SetActions }         from '@/store/actions/set';

class SidebarHeader extends React.Component {
    render() {
        return (
            <StatelessSidebarHeader
                newSetOnClick={this.onNewSetClicked}
            />
        );
    }

    onNewSetClicked = () => {
        this.props.newSet({
            name: null,
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SidebarHeader;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    SetActions,
), dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(Component);
