//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Jimp from 'jimp';
import fs   from 'fs';

export const convertNumberToByteArray = (number) => {
    const array = new ArrayBuffer(4); // an Int32 takes 4 bytes
    const view  = new DataView(array);

    view.setUint32(0, number, true);

    return new Uint8Array(array);
};

export const writeBinaryDataToFile = (outputPath, data) => {
    return new Promise((resolve, reject) => {
        fs.writeFile(outputPath, data, 'binary', () => {
            resolve();
        });
    });
};

/**
 * Example usage:
 *
 * convertPngToEpdByteArray('./testData/Mozarts_Woodwind5quer.png').then((epdData) => {
 *     writeBinaryDataToFile(
 *         '/Users/michael/Desktop/_customer/sonatis/JOBS-398_attachments/Mozarts_Woodwind5quer.js.son',
 *         epdData,
 *     ).then(() => {
 *         console.log('Done');
 *     });
 * });
 */
export const convertPngToEpdByteArray = async (pngPathOrArrayBuffer) => {
    console.groupCollapsed('EpdConverter: Convert image to epd');
    console.time('read image');

    let image = await Jimp.read(pngPathOrArrayBuffer);

    console.timeEnd('read image');

    let imageWidth  = image.bitmap.width;
    let imageHeight = image.bitmap.height;

    console.time('resize and rotate');

    if (imageHeight > imageWidth) {
        const canvas      = document.createElement('canvas');
        canvas.style      = 'border: 3px solid crimson';
        const context     = canvas.getContext('2d');
        canvas.width      = 1600;
        canvas.height     = 1200;
        context.fillStyle = 'white';

        context.fillRect(0, 0, canvas.width, canvas.height);

        image = await new Promise((resolve) => {
            const canvasImage  = new Image();
            canvasImage.onload = function () {
                // Save the unrotated context of the canvas so we can restore it later
                // the alternative is to untranslate & unrotate after drawing
                context.save();

                // Move to the center of the canvas
                context.translate(canvas.width / 2, canvas.height / 2);

                // Rotate the canvas to the specified degrees
                context.rotate(90 * Math.PI / 180);

                // Draw the image. Since the context is rotated, the image will be rotated also
                context.drawImage(canvasImage, -canvasImage.width / 2, -canvasImage.height / 2);

                // We’re done with the rotating so restore the unrotated context
                context.restore();
                canvas.toBlob(
                    (blob) => {
                        const reader = new FileReader();

                        reader.addEventListener('loadend', async () => {
                            Jimp.read(reader.result, (error, image) => {
                                resolve(image);
                            });
                        });
                        reader.readAsArrayBuffer(blob);
                    },
                    'image/png',
                );
            };

            const reader  = new FileReader();
            reader.onload = (event) => {
                canvasImage.src = event.target.result;
            };
            reader.readAsDataURL(new Blob([pngPathOrArrayBuffer]));
        });

        imageWidth  = image.bitmap.width;
        imageHeight = image.bitmap.height;
    }

    console.timeEnd('resize and rotate');
    console.time('convert to epd');

    const pixelsNumber   = imageWidth * imageHeight;
    const resolution     = [imageWidth, imageHeight];
    const pixProByte     = 4;
    const grayscale      = 4;
    const tabNumber      = Math.floor(Math.log(grayscale) / Math.log(2));
    const rowImageLength = Math.floor((
        pixelsNumber / pixProByte
    ) * tabNumber);
    const rowImage       = new Array(rowImageLength);
    const tabIndex       = [0, rowImageLength / tabNumber];

    rowImage.fill(0);

    for (let y = 0; y < imageHeight; y++) {
        for (let x = 0; x < imageWidth;) {
            const pixelTable = [0, 0];

            for (let byteIndex = 0; byteIndex < 8; byteIndex += 2) {
                // @formatter:off
                /* eslint-disable no-mixed-operators */
                // Calculate the average (a form of greyscale)
                let avg = 0;

                if (imageWidth - x > 0) {
                    const color = image.getPixelColor(imageWidth - x - 1, imageHeight - y - 1);
                    const red   = color >> 24 & 0xFF;
                    const green = color >> 16 & 0xFF;
                    const blue  = color >> 8 & 0xFF;
                    avg         = (((red + green + blue) / 3) >> 6) & 0x03;
                    x++;
                }

                // Create pixTab
                switch (avg) {
                    case 0:
                        pixelTable[1] |= (0b01 << (6 - byteIndex));
                        pixelTable[0] |= (0b01 << (6 - byteIndex));

                        break;
                    case 1:
                        pixelTable[1] |= (0b01 << (6 - byteIndex));
                        pixelTable[0] |= (0b10 << (6 - byteIndex));

                        break;
                    case 2:
                        pixelTable[1] |= (0b10 << (6 - byteIndex));
                        pixelTable[0] |= (0b01 << (6 - byteIndex));

                        break;
                    case 3:
                        pixelTable[1] |= (0b10 << (6 - byteIndex));
                        pixelTable[0] |= (0b10 << (6 - byteIndex));

                        break;
                }
            }

            if (
                tabIndex[0] > 1 &&
                rowImage[tabIndex[0] - 1] !== 255 &&
                rowImage[tabIndex[0] - 2] === pixelTable[0]
            ) {
                rowImage[tabIndex[0] - 1] += 1;
            } else {
                rowImage[tabIndex[0]] = pixelTable[0];
                rowImage[tabIndex[0] + 1]++;
                tabIndex[0] += 2;
            }

            if (
                tabIndex[1] > rowImageLength / tabNumber + 1 &&
                rowImage[tabIndex[1] - 1] !== 255 &&
                rowImage[tabIndex[1] - 2] === pixelTable[1]
            ) {
                rowImage[tabIndex[1] - 1] += 1;
            } else {
                rowImage[tabIndex[1]] = pixelTable[1];
                rowImage[tabIndex[1] + 1]++;
                tabIndex[1] += 2;
            }
            /* eslint-enable no-mixed-operators */
            // @formatter:on
        }
    }

    const startIndex        = rowImage.length / tabNumber;
    const headerBytes       = 8;
    const compArrayLength   = tabIndex[0] + tabIndex[1] - startIndex + headerBytes;
    const compArray         = new Uint8Array(compArrayLength);
    const fileVolume        = convertNumberToByteArray(compArrayLength);
    const fileVolumeView    = new DataView(fileVolume.buffer);
    const rowImageArray     = new Uint8Array(rowImage);
    const compArrayView     = new DataView(compArray.buffer);
    const rowImageArrayView = new DataView(rowImageArray.buffer);

    // C# Code: Buffer.BlockCopy(fileVolume, 0, compArray, 0, fileVolume.Length);
    for (
        let source = 0, destination = 0;
        source < fileVolume.length && destination < compArrayLength;
        source++, destination++
    ) {
        const value = fileVolumeView.getUint8(source);

        compArrayView.setUint8(destination, value);
    }

    // C# Code: Buffer.BlockCopy(imageResol, 0, compArray, 4, imageResol.Length * sizeof(UInt16));
    /* eslint-disable no-mixed-operators */
    for (
        let source = 0, destination = 4;
        source < resolution.length && destination < compArrayLength;
        source++, destination += 2
    ) {
        let value   = resolution[source];
        const bytes = [
            (
                value & 0xff00
            ) >> 8,
            (
                value & 0x00ff
            ),
        ];
        value       = bytes[0] | bytes[1] << 8;

        compArrayView.setUint16(destination, value);
    }

    /* eslint-enable no-mixed-operators */
    // C# Code: Array.Copy(rowImage, 0, compArray, headerBytes, tabIndex[0]);
    for (
        let source = 0, destination = headerBytes;
        source < tabIndex[0] && destination < compArrayLength;
        source++, destination++
    ) {
        const value = rowImageArrayView.getUint8(source);

        compArrayView.setInt8(destination, value);
    }

    // C# Code: Array.Copy(rowImage, startIndex, compArray, headerBytes + tabIndex[0], tabIndex[1] - startIndex);
    for (
        let source = startIndex, destination = headerBytes + tabIndex[0];
        source < tabIndex[1] && destination < compArrayLength;
        source++, destination++
    ) {
        const value = rowImageArrayView.getUint8(source);

        compArrayView.setInt8(destination, value);
    }

    console.timeEnd('convert to epd');
    console.groupEnd();

    return compArrayView;
}

export default convertPngToEpdByteArray;
