//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes  from 'prop-types';
import classNames      from 'classnames';
import ComponentHelper from '@/helper/ComponentHelper';
import LabelTheme      from './LabelTheme';
import React           from 'react';
import styles          from './styles.module.scss';
import LabelSize       from '@/components/stateless/atomic/Label/LabelSize';

class Label extends React.Component {
    render() {
        return (
            <div
                className={classNames(
                    styles.label,
                    {
                        [styles.grow]:      this.props.grow,
                        [styles.dark]:      this.props.theme === LabelTheme.dark,
                        [styles.darkLight]: this.props.theme === LabelTheme.darkLight,
                        [styles.small]:     this.props.size === LabelSize.small,
                    },
                )}
            >
                {this.props.text}
                {this.props.children}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Label;

Component.propTypes = {
    children: PropTypes.children,
    grow:     PropTypes.bool,
    size:     PropTypes.oneOf(Object.values(LabelSize)),
    text:     PropTypes.string,
    theme:    PropTypes.oneOf(Object.values(LabelTheme)),
};

Component.defaultProps = {
    children: null,
    grow:     false,
    text:     '',
    theme:    LabelTheme.default,
    size:     LabelSize.default,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
