//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _            from 'lodash';
import I18n         from 'i18next';
import update       from 'immutability-helper';
import { SetTypes } from '@/store/actions/set';

const emptySet = {
    files: [],
    name:  I18n.t('setNamePlaceholder'),
};

const initialState = {
    activeSetIndex: null,
    sets:           [],
};

const moveFilesInArrayByIndex = (files, index, nextIndex) => {
    const filesCopy = _.cloneDeep(files);
    const file      = filesCopy[index];

    filesCopy.splice(index, 1);
    filesCopy.splice(nextIndex, 0, file);

    return filesCopy;
};

const moveFileDownByIndex = (files, index) => {
    return moveFilesInArrayByIndex(files, index, index + 1);
};

const moveFileUpByIndex = (files, index) => {
    return moveFilesInArrayByIndex(files, index, index - 1);
};

const addFiles = (action, state) => {
    const files          = action.files;
    const activeSetIndex = state.activeSetIndex;

    return update(state, {
        sets: {
            [activeSetIndex]: {
                files: {
                    $push: files,
                },
            },
        },
    });
};

const changeActiveSet = (action, state) => {
    return update(state, {
        activeSetIndex: {
            $set: action.activeSetIndex,
        },
    });
};

const deleteFile = (action, state) => {
    return update(state, {
        sets: {
            [state.activeSetIndex]: {
                files: {
                    $splice: [
                        [
                            action.fileIndex,
                            1,
                        ],
                    ],
                },
            },
        },
    });
};

const deleteSet = (action, state) => {
    return update(state, {
        sets:           {
            $splice: [
                [
                    state.activeSetIndex,
                    1,
                ],
            ],
        },
        activeSetIndex: {
            $set: 0,
        },
    });
};

const moveFileDown = (action, state) => {
    return update(state, {
        sets: {
            [state.activeSetIndex]: {
                files: {
                    $set: moveFileDownByIndex(state.sets[state.activeSetIndex].files, action.fileIndex),
                },
            },
        },
    });
};

const moveFileUp = (action, state) => {
    return update(state, {
        sets: {
            [state.activeSetIndex]: {
                files: {
                    $set: moveFileUpByIndex(state.sets[state.activeSetIndex].files, action.fileIndex),
                },
            },
        },
    });
};

const newSet = (action, state) => {
    const clonedSets = _.cloneDeep(state.sets);
    const newSet     = _.cloneDeep(emptySet);

    if (action.name) {
        newSet.name = action.name;
    }

    clonedSets.push(newSet);

    return update(state, {
        sets:           {
            $set: clonedSets,
        },
        activeSetIndex: {
            $set: clonedSets.length - 1,
        },
    });
};

const renameSet = (action, state) => {
    const clonedSets                      = _.cloneDeep(state.sets);
    clonedSets[state.activeSetIndex].name = action.name;

    return update(state, {
        sets:           {
            $set: clonedSets,
        },
        activeSetIndex: {
            $set: clonedSets.length - 1,
        },
    });
};

const renameSetFile = (action, state) => {
    return update(state, {
        sets: {
            [state.activeSetIndex]: {
                files: {
                    [action.fileIndex]: {
                        name: {
                            $set: action.name,
                        },
                    },
                },
            },
        },
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case SetTypes.ADD_FILES:         return addFiles(action, state);
        case SetTypes.CHANGE_ACTIVE_SET: return changeActiveSet(action, state);
        case SetTypes.DELETE_FILE:       return deleteFile(action, state);
        case SetTypes.DELETE_SET:        return deleteSet(action, state);
        case SetTypes.MOVE_FILE_DOWN:    return moveFileDown(action, state);
        case SetTypes.MOVE_FILE_UP:      return moveFileUp(action, state);
        case SetTypes.NEW_SET:           return newSet(action, state);
        case SetTypes.RENAME_SET:        return renameSet(action, state);
        case SetTypes.RENAME_SET_FILE:   return renameSetFile(action, state);
        default:                         return state;
        // @formatter:on
    }
}
