//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes            from 'prop-types';
import ComponentHelper           from '@/helper/ComponentHelper';
import React                     from 'react';
import StatelessMessageOverlay   from '@/components/stateless/atomic/MessageOverlay';
import { bindActionCreators }    from 'redux';
import { connect }               from 'react-redux';
import { MessageOverlayActions } from '@/store/actions/messageOverlay';

class MessageOverlay extends React.Component {
    render() {
        return (
            <StatelessMessageOverlay
                messageOverlayOpen={this.props.messageOverlayOpen}
                closeMessageOverlayClicked={this.closeMessageOverlayClicked}
                title={this.props.title}
                text={this.props.text}
                hint={this.props.hint}
            />
        );
    }

    closeMessageOverlayClicked = () => {
        this.props.closeOverlay();
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = MessageOverlay;

Component.propTypes = {
    hint:               PropTypes.string,
    messageOverlayOpen: PropTypes.bool,
    text:               PropTypes.string,
    title:              PropTypes.string,
};

Component.defaultProps = {
    hint:               null,
    messageOverlayOpen: false,
    text:               null,
    title:              null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        hint:               state.messageOverlay.hint,
        messageOverlayOpen: state.messageOverlay.messageOverlayOpen,
        text:               state.messageOverlay.text,
        title:              state.messageOverlay.title,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators({
    ...MessageOverlayActions,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
