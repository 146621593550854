//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes      from 'prop-types';
import classNames          from 'classnames';
import ComponentHelper     from '@/helper/ComponentHelper';
import EmptyDashboardImage from '@/components/stateless/atomic/EmptyDashbaord/EmptyDashboardImage';
import React               from 'react';
import styles              from './styles.module.scss';

class EmptyDashboard extends React.Component {
    render () {
        return (
            <div
                className={classNames(
                    styles.emptyDashboardContainer,
                    {
                        [styles.wave]:    this.props.image === EmptyDashboardImage.wave,
                        [styles.noFiles]: this.props.image === EmptyDashboardImage.noFiles,
                    },
                )}
            >
                <h1>
                    {this.props.title}
                </h1>
                {
                    this.props.message.length ? (
                        <p>
                            {this.props.message}
                        </p>
                    ) : null
                }
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = EmptyDashboard;

Component.propTypes = {
    image:   PropTypes.oneOf(Object.keys(EmptyDashboardImage)),
    message: PropTypes.string,
    title:   PropTypes.string,
};

Component.defaultProps = {
    image:   EmptyDashboardImage.none,
    message: '',
    title:   '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
