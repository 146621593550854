//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const ConfirmOverlayTypes = {
    CLOSE_OVERLAY:          'ConfirmOverlay/CLOSE_OVERLAY',
    IMPORT_INTO_ACTIVE_SET: 'ConfirmOverlay/IMPORT_INTO_ACTIVE_SET',
    IMPORT_INTO_NEW_SET:    'ConfirmOverlay/IMPORT_INTO_NEW_SET',
    OPEN_OVERLAY:           'ConfirmOverlay/OPEN_OVERLAY',
    SET_TEXT_AND_TITLE:     'ConfirmOverlay/SET_TEXT_AND_TITLE',
};

const closeOverlay = makeActionCreator(
    ConfirmOverlayTypes.CLOSE_OVERLAY,
);

const importIntoActiveSet = makeActionCreator(
    ConfirmOverlayTypes.IMPORT_INTO_ACTIVE_SET,
);

const importIntoNewSet = makeActionCreator(
    ConfirmOverlayTypes.IMPORT_INTO_NEW_SET,
);

const openOverlay = makeActionCreator(
    ConfirmOverlayTypes.OPEN_OVERLAY,
);

const setTextAndTitle = makeActionCreator(
    ConfirmOverlayTypes.SET_TEXT_AND_TITLE,
    {
        text:  null,
        title: null,
    },
);

export const ConfirmOverlayActions = {
    closeOverlay,
    importIntoActiveSet,
    importIntoNewSet,
    openOverlay,
    setTextAndTitle,
};
