//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import { all }        from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { takeEvery }  from 'redux-saga/effects';
import { REHYDRATE }  from 'redux-persist';

// Types
import { FileStoreTypes } from '@/store/actions/fileStore';
import { SetTypes }       from '@/store/actions/set';

// Actions
import { LoadingOverlayActions } from '@/store/actions/loadingOverlay';

// Sagas
import ClientSaga              from './client';
import FileStoreSaga           from './fileStore';
import SetSagas                from './set';
import { LoadingOverlayTypes } from '@/store/actions/loadingOverlay';

const root = function* () {
    yield all([
        // @formatter:off
        takeEvery([SetTypes.CONVERT_SET],                      SetSagas.convertSet),
        takeEvery([SetTypes.TRY_ADD_FILES],                    SetSagas.tryAddFiles),
        takeLatest([SetTypes.DOWNLOAD_SET],                    SetSagas.downloadSet),
        takeLatest([SetTypes.DOWNLOAD_SETS],                   SetSagas.downloadSets),
        takeLatest([FileStoreTypes.DELETE_FILE],               FileStoreSaga.deleteFile),
        takeLatest([LoadingOverlayTypes.PREPARATION_STARTED],  ClientSaga.keepAwake),
        takeLatest([LoadingOverlayTypes.PREPARATION_FINISHED], ClientSaga.allowToSleep),
        takeLatest([REHYDRATE],                                rehydrationDone),
        // @formatter:on
    ]);
};

const rehydrationDone = function* () {
    yield preLoading();
};

const preLoading = function* () {
    yield put(LoadingOverlayActions.preparationFinished());
};

export default {
    root,
};
