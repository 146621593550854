//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes         from 'prop-types';
import _                      from 'lodash';
import ComponentHelper        from '@/helper/ComponentHelper';
import React                  from 'react';
import StatelessSetFiles      from '@/components/stateless/composed/SetFiles';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { SetActions }         from '@/store/actions/set';

class SetFiles extends React.Component {
    render () {
        return (
            <StatelessSetFiles
                files={this.props.files}
                onDeleteSetFileClicked={this.onDeleteSetFileClicked}
                onMoveFileUpClicked={this.onMoveFileUpClicked}
                onMoveFileDownClicked={this.onMoveFileDownClicked}
                onNameChange={this.onNameChange}
            />
        );
    }

    onDeleteSetFileClicked = (fileIndex) => {
        this.props.deleteFile(fileIndex);
    };

    onMoveFileUpClicked = (fileIndex) => {
        this.props.moveFileUp(fileIndex);
    };

    onMoveFileDownClicked = (fileIndex) => {
        this.props.moveFileDown(fileIndex);
    };

    onNameChange = (file) => {
        this.props.renameSetFile(file);
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SetFiles;

Component.propTypes = {
    files: PropTypes.array,
};

Component.defaultProps = {
    files: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    SetActions,
), dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(Component);
