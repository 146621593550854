//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { activateKeepAwake }   from 'expo-keep-awake';
import { deactivateKeepAwake } from 'expo-keep-awake';

const keepAwake = function () {
    activateKeepAwake();
};

const allowToSleep = function () {
    deactivateKeepAwake();
};

export default {
    keepAwake, allowToSleep,
};
