//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes  from 'prop-types';
import _               from 'lodash';
import AddButton       from '@/components/stateless/atomic/AddButton';
import AddButtonTheme  from '@/components/stateless/atomic/AddButton/AddButtonTheme';
import ComponentHelper from '@/helper/ComponentHelper';
import I18n            from 'i18next';
import Label           from '@/components/stateless/atomic/Label';
import LabelTheme      from '@/components/stateless/atomic/Label/LabelTheme';
import React           from 'react';
import styles          from './styles.module.scss';
import Tooltip         from '@/components/connected/Tooltip';
import TooltipPosition from '@/components/stateless/atomic/Tooltip/TooltipPosition';

class SetHeader extends React.Component {
    render () {
        return (
            <div className={styles.setHeader}>
                <Label
                    text={I18n.t('filesInSet') + ' ' + this.props.setCount}
                    theme={LabelTheme.dark}
                />
                <AddButton
                    onClick={this.onOpenOverlayClicked}
                    theme={AddButtonTheme.red}
                />
                <Tooltip
                    identifier={'setListFile'}
                    text={I18n.t('tooltipNewFiles')}
                    top={70}
                    right={454}
                    position={TooltipPosition.top}
                />
            </div>
        );
    }

    onOpenOverlayClicked = () => {
        this.props.openOverlayOnClick();
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SetHeader;

Component.propTypes = {
    openOverlayOnClick: PropTypes.func,
    setCount:           PropTypes.number,
};

Component.defaultProps = {
    openOverlayOnClick: _.noop,
    setCount:           0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
