//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes  from 'prop-types';
import _               from 'lodash';
import AddButtonTheme  from './AddButtonTheme';
import classNames      from 'classnames';
import ComponentHelper from '@/helper/ComponentHelper';
import Icon            from '@/components/stateless/atomic/Icon';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import React           from 'react';
import styles          from './styles.module.scss';

class AddButton extends React.Component {
    render () {
        return (
            <div
                className={classNames(
                    styles.addButton,
                    {
                        [styles.grey]: this.props.theme === AddButtonTheme.grey,
                        [styles.red]:  this.props.theme === AddButtonTheme.red,
                    },
                )}
                onClick={this.props.onClick}
            >
                <Icon iconType={IconType.plusCircle} />
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = AddButton;

Component.propTypes = {
    onClick: PropTypes.func,
    theme:   PropTypes.oneOf(Object.values(AddButtonTheme)),
};

Component.defaultProps = {
    onClick: _.noop,
    theme:   AddButtonTheme.grey,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
