//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import DropIn        from '@/components/stateless/atomic/DropIn';
import React         from 'react';
import Routes        from '@/constants/Routes';
import Set           from '@/components/stateless/composed/Set';
import Sidebar       from '@/components/stateless/composed/Sidebar';
import styles        from './styles.module.scss';
import { Component } from 'react';
import { connect }   from 'react-redux';

class Screen extends Component {
    sets       = [
        {
            name: 'Frank Sinatra',
            files: [
                {
                    name: 'Keane Somewhere only we know',
                    file: 'Keane_Somewhere_only_we_know.pdf',
                    date: '14.11.2020',
                },
                {
                    name: 'Keane Somewhere only we know',
                    file: 'Keane_Somewhere_only_we_know.pdf',
                    date: '14.11.2020',
                },
                {
                    name: 'Keane Somewhere only we know',
                    file: 'Keane_Somewhere_only_we_know.pdf',
                    date: '14.11.2020',
                },
                {
                    name: 'Keane Somewhere only we know',
                    file: 'Keane_Somewhere_only_we_know.pdf',
                    date: '14.11.2020',
                },
            ],
        },
        {
            name: 'Konzert 14.11.2020',
            files: [
                {
                    name: 'Keane Somewhere only we know',
                    file: 'Keane_Somewhere_only_we_know.pdf',
                    date: '14.11.2020',
                },
                {
                    name: 'Keane Somewhere only we know',
                    file: 'Keane_Somewhere_only_we_know.pdf',
                    date: '14.11.2020',
                },
                {
                    name: 'Keane Somewhere only we know',
                    file: 'Keane_Somewhere_only_we_know.pdf',
                    date: '14.11.2020',
                },
                {
                    name: 'Keane Somewhere only we know',
                    file: 'Keane_Somewhere_only_we_know.pdf',
                    date: '14.11.2020',
                },
                {
                    name: 'Keane Somewhere only we know',
                    file: 'Keane_Somewhere_only_we_know.pdf',
                    date: '14.11.2020',
                },
            ],
        },
        {
            name: 'Mozart: Don Giovanni',
            files: [
                {
                    name: 'Keane Somewhere only we know',
                    file: 'Keane_Somewhere_only_we_know.pdf',
                    date: '14.11.2020',
                },
                {
                    name: 'Keane Somewhere only we know',
                    file: 'Keane_Somewhere_only_we_know.pdf',
                    date: '14.11.2020',
                },
                {
                    name: 'Keane Somewhere only we know',
                    file: 'Keane_Somewhere_only_we_know.pdf',
                    date: '14.11.2020',
                },
                {
                    name: 'Keane Somewhere only we know',
                    file: 'Keane_Somewhere_only_we_know.pdf',
                    date: '14.11.2020',
                },
                {
                    name: 'Keane Somewhere only we know',
                    file: 'Keane_Somewhere_only_we_know.pdf',
                    date: '14.11.2020',
                },
            ],
        },
    ];
    currentSet = this.sets[0];

    render () {
        if (this.props.pathname === Routes.screenDesign) {
            return (
                <div className={styles.screenDesign}>
                    <Sidebar sets={this.sets} />
                    <Set set={this.currentSet} />
                </div>
            );
        }

        return (
            <div className={styles.screenDesign}>
                <Sidebar sets={this.sets} />
                <Set set={this.currentSet} />
                <DropIn />
            </div>
        );
    }
}

const mapStateToProps = state => (
    {
        pathname: state.router.location.pathname,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Screen);
