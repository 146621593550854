//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                     from 'react';
import * as PropTypes            from 'prop-types';
import ComponentHelper           from '@/helper/ComponentHelper';
import StatelessLoadingOverlay   from '@/components/stateless/atomic/LoadingOverlay';
import { bindActionCreators }    from 'redux';
import { connect }               from 'react-redux';
import { LoadingOverlayActions } from '@/store/actions/loadingOverlay';
import { SetActions }            from '@/store/actions/set';

class LoadingOverlay extends React.Component {
    abortFileExport = () => {
        this.props.abortDownload();
        this.props.preparationFinished();
    };

    render() {
        return (
            <StatelessLoadingOverlay
                abortFileExport={this.abortFileExport}
                buildingMenu={this.props.buildingMenu}
                currentFile={this.props.currentFile}
                fileCount={this.props.fileCount}
                timeNeededInSeconds={this.props.timeNeededInSeconds}
                isInPreDownload={this.props.isInPreDownload}
                isUpload={this.props.isUpload}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = LoadingOverlay;

Component.propTypes = {
    buildingMenu:        PropTypes.bool,
    currentFile:         PropTypes.number,
    fileCount:           PropTypes.number,
    isInPreDownload:     PropTypes.bool,
    isUpload:            PropTypes.bool,
    timeNeededInSeconds: PropTypes.number,
};

Component.defaultProps = {
    buildingMenu:        false,
    currentFile:         0,
    fileCount:           0,
    isInPreDownload:     false,
    isUpload:            false,
    timeNeededInSeconds: 0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        buildingMenu:        state.loadingOverlay.buildingMenu,
        currentFile:         state.loadingOverlay.currentFile,
        fileCount:           state.loadingOverlay.fileCount,
        isInPreDownload:     state.loadingOverlay.isInPreDownload,
        isUpload:            state.loadingOverlay.isUpload,
        timeNeededInSeconds: state.loadingOverlay.timeNeededInSeconds,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...LoadingOverlayActions,
        ...SetActions
    },
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
