//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import * as PropTypes   from 'prop-types';
import _                from 'lodash';
import classNames       from 'classnames';
import ComponentHelper  from '@/helper/ComponentHelper';
import FileUpload       from '@/components/stateless/atomic/FileUpload';
import FileUploadHelper from '@/helper/FileUpload';
import File             from '@/helper/File';
import I18n             from 'i18next';
import IconButton       from '@/components/stateless/atomic/IconButton';
import IconButtonTheme  from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import IconType         from '@/components/stateless/atomic/Icon/IconType';
import styles           from './styles.module.scss';
import { FileDrop }     from 'react-file-drop';

class DropIn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openOnDrag: false,
            dropInOpen: props.dropInOpen,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dropInOpen !== this.props.dropInOpen) {
            this.setState({
                dropInOpen: this.props.dropInOpen,
            });
        }
    }

    render() {
        return (
            <>
                <div
                    className={classNames(
                        styles.dropInContainer,
                        {
                            [styles.dropInContainerInvisible]: !this.state.dropInOpen,
                        },
                    )}
                >
                    <div className={styles.dropIn}>
                        <div className={styles.dropInCloseContainer}>
                            <IconButton
                                iconType={IconType.cross}
                                theme={IconButtonTheme.transparent}
                                onClick={this.closeDropInClicked}
                            />
                        </div>
                        <div className={styles.dropInText}>
                            <h2>
                                {I18n.t('addToSet')}
                            </h2>
                            <p>
                                {I18n.t('addToSetText')}
                            </p>
                        </div>
                        <div className={styles.dropInHint}>
                            <p>
                                {I18n.t('addToSetFileNameHint')}
                            </p>
                        </div>
                        <FileUpload
                            onLoadingFileComplete={this.loadingFilesDone}
                            dropInOpen={this.state.dropInOpen && !this.state.openOnDrag}
                        />
                    </div>
                </div>
                {this.renderFileDrop()}
            </>
        );
    }

    renderFileDrop = () => {
        return (
            <FileDrop
                className={styles.fileDrop}
                onFrameDrop={this.onFilesDropped}
                onFrameDragEnter={this.onDragEnter}
                onFrameDragLeave={this.onDragLeave}
            />
        );
    };

    onDragEnter = () => {
        this.setState({
            openOnDrag: true,
            dropInOpen: true,
        });
    };

    onDragLeave = () => {
        this.setState({
            dropInOpen: false,
        });
    };

    onLoadingFileComplete = (pdfFiles, zipFiles) => {
        this.props.loadingFilesDone(pdfFiles, zipFiles);
    };

    onFilesDropped = async (event) => {
        const uploadedFiles                    = event.dataTransfer.files;
        const { zip: zipFiles, pdf: pdfFiles } = File.filterFileTypes(uploadedFiles);
        const addedFiles                       = await FileUploadHelper.addFiles(pdfFiles);

        this.onLoadingFileComplete(addedFiles, zipFiles);

        if (this.state.openOnDrag) {
            this.closeDropInClicked();
            this.setState({
                dropInOpen: false,
                openOnDrag: false,
            });
        }
    };

    closeDropInClicked = () => {
        this.props.closeDropInClicked();
    };

    loadingFilesDone = (file) => {
        this.props.loadingFilesDone(file);
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = DropIn;

Component.propTypes = {
    closeDropInClicked: PropTypes.func,
    dropInOpen:         PropTypes.bool,
    isSetActive:        PropTypes.bool,
    loadingFilesDone:   PropTypes.func,
};

Component.defaultProps = {
    closeDropInClicked: _.noop,
    dropInOpen:         false,
    isSetActive:        false,
    loadingFilesDone:   _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'dropInOpen',
];

export default Component;
