//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes  from 'prop-types';
import _               from 'lodash';
import AddButton       from '@/components/stateless/atomic/AddButton';
import ComponentHelper from '@/helper/ComponentHelper';
import I18n            from 'i18next';
import Label           from '@/components/stateless/atomic/Label';
import React           from 'react';
import styles          from './styles.module.scss';
import ExportPopupType from '@/components/stateless/composed/ExportPopup/ExportPopupType';
import ExportPopup     from '@/components/connected/ExportPopup';

class SidebarHeader extends React.Component {
    render() {
        return (
            <div className={styles.sidebarHeaderContainer}>
                <Label text={I18n.t('yourSetList')}>
                    <ExportPopup type={ExportPopupType.sets} />
                </Label>
                <AddButton onClick={this.onNewSetClicked} />
            </div>
        );
    }

    onNewSetClicked = () => {
        this.props.newSetOnClick();
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SidebarHeader;

Component.propTypes = {
    newSetOnClick: PropTypes.func,
};

Component.defaultProps = {
    newSetOnClick: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
