//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper from '@/helper/ComponentHelper';
import I18n            from 'i18next';
import Image           from 'react-fix-image-orientation';
import React           from 'react';
import Routes          from '@/constants/Routes';
import styles          from './styles.module.scss';
import { Link }        from 'react-router-dom';

class Logo extends React.Component {
    render () {
        return (
            <Link
                to={Routes.dashboard}
                className={styles.logoIcon}
            >
                <Image
                    src={require('../../../../assets/images/logo.png')}
                    alt={I18n.t('sonatisLogo')}
                />
            </Link>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Logo;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
