//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { UserTypes } from '../actions/user';
import update        from 'immutability-helper';

const initialState = {
    tooltips: {
        setList:       true,
        setListFile:   true,
        setListExport: false,
        setExport:     false,
    },
};

const setTooltipHide = (action, state) => {
    const additionalChanges = {};

    if (action.identifier === 'setListFile') {
        additionalChanges['setListExport'] = { $set: true };
        additionalChanges['setExport']     = { $set: true };
    }

    return update(state, {
        tooltips: {
            [action.identifier]: {
                $set: false,
            },
            ...additionalChanges,
        },
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case UserTypes.SET_TOOLTIP_HIDE:  return setTooltipHide(action, state);
        default:                          return state;
        // @formatter:on
    }
}
