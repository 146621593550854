//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes      from 'prop-types';
import _                   from 'lodash';
import classNames          from 'classnames';
import ComponentHelper     from '@/helper/ComponentHelper';
import EmptyDashboard      from '@/components/stateless/atomic/EmptyDashbaord';
import EmptyDashboardImage from '@/components/stateless/atomic/EmptyDashbaord/EmptyDashboardImage';
import I18n                from 'i18next';
import React               from 'react';
import SetDetail           from '@/components/connected/SetDetail';
import SetFiles            from '@/components/connected/SetFiles';
import SetHeader           from '@/components/connected/SetHeader';
import styles              from './styles.module.scss';

class Set extends React.Component {
    render () {
        const set              = _.get(this.props, 'set', {});
        const isSetEmpty       = Object.keys(set).length === 0;
        const filesCount       = _.get(set, 'files.length', 0);
        const areSetFilesEmpty = filesCount === 0;
        const setName          = _.get(set, 'name', '');

        return (
            <div
                className={classNames(
                    styles.set,
                    {
                        [styles.filled]: !isSetEmpty,
                    },
                )}
            >
                <div className={styles.setContentContainer}>
                    {this.renderHeader(isSetEmpty, filesCount)}
                    <div className={styles.setFilesContainer}>
                        {this.renderFiles(isSetEmpty, areSetFilesEmpty)}
                    </div>
                </div>
                {this.renderSetDetails(isSetEmpty, setName)}
            </div>
        );
    }

    renderHeader (isSetEmpty, filesCount) {
        if (!isSetEmpty) {
            return (
                <SetHeader setCount={filesCount} />
            );
        }

        return null;
    }

    renderFiles (isSetEmpty, areSetFilesEmpty) {
        if (!areSetFilesEmpty) {
            return (
                <SetFiles files={this.props.set.files} />
            );
        }

        return this.renderMessage(isSetEmpty);
    }

    renderSetDetails (isSetEmpty, setName) {
        if (!isSetEmpty) {
            return (
                <SetDetail name={setName} />
            );
        }

        return null;
    }

    renderMessage (isSetEmpty) {
        if (isSetEmpty) {
            return (
                <EmptyDashboard
                    title={I18n.t('welcome')}
                    message={I18n.t('welcomeMessage')}
                    image={EmptyDashboardImage.wave}
                />
            );
        }

        return (
            <EmptyDashboard
                title={I18n.t('noFiles')}
                message={I18n.t('noFilesMessage')}
                image={EmptyDashboardImage.noFiles}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Set;

Component.propTypes = {
    set: PropTypes.object,
};

Component.defaultProps = {
    set: {},
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
