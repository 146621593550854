//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                  from 'lodash';
import { SetTypes }       from '@/store/actions/set';
import { FileStoreTypes } from '@/store/actions/fileStore';

const setDeleteFile = store => next => action => {
    if (action.type === SetTypes.DELETE_FILE) {
        const state          = store.getState();
        const sets           = state.set.sets;
        const activeSetIndex = state.set.activeSetIndex;
        const fileId         = _.get(
            sets,
            [
                activeSetIndex,
                'files',
                action.fileIndex,
                'fileId',
            ],
        );

        const storeDeleteFileAction = {
            type:   FileStoreTypes.DELETE_FILE,
            fileId: fileId,
        };

        next(storeDeleteFileAction);
    }

    return next(action);
};

export default setDeleteFile;
