//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper from '@/helper/ComponentHelper';
import I18n            from 'i18next';
import IconButton      from '@/components/stateless/atomic/IconButton';
import PropTypes       from '@/components/PropTypes';
import React           from 'react';
import Routes          from '@/constants/Routes';
import styles          from './styles.module.scss';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import IconButtonSize  from '@/components/stateless/atomic/IconButton/IconButtonSize';
import IconButtonTheme from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import Navigation      from '@/helper/Navigation';

class BreadCrumbBar extends React.Component {
    getBreadCrumbRouteStack = () => {
        const breadCrumbRouteStack = [
            this.props.pathname,
        ];

        return breadCrumbRouteStack;
    };

    getLabelForRoute = (route) => {
        switch (route) {
            // @formatter:off
            case Routes.dashboard:                return I18n.t('management');
            case Routes.screenDesign:             return I18n.t('screenDesign');
            case Routes.examples:                 return I18n.t('examples');
            case Routes.home:                     return I18n.t('home');
            case Routes.imprint:                  return I18n.t('imprint');
            case Routes.privacy:                  return I18n.t('privacy');
            case Routes.inventory:                return I18n.t('inventory');
            case Routes.inventoryDetails:         return I18n.t('inventoryDetails');
            case Routes.login:                    return I18n.t('login');
            // @formatter:on
        }

        console.error('Missing label for route:', route);

        return null;
    };

    render () {
        const breadCrumbRouteStack = this.getBreadCrumbRouteStack();

        return (
            <div className={styles.breadCrumbBar}>
                <ul>
                    {this.renderBackButton()}
                    {breadCrumbRouteStack.map(this.renderBreadCrumbRoute)}
                </ul>
            </div>
        );
    }

    renderBackButton = () => {
        if (
            this.props.pathname === Routes.imprint ||
            this.props.pathname === Routes.privacy
        ) {
            return (
                <li className={styles.backButton}>
                    <IconButton
                        iconType={IconType.chevron}
                        size={IconButtonSize.small}
                        theme={IconButtonTheme.transparent}
                        onClick={this.backButtonPressed}
                    />
                </li>
            );
        }

        return null;
    };

    backButtonPressed = () => {
        Navigation.goBack();
    };

    renderBreadCrumbRoute = (breadCrumbRoute, index) => {
        const label = this.getLabelForRoute(breadCrumbRoute);

        return (
            <li key={index}>
                {label}
            </li>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = BreadCrumbBar;

Component.propTypes = {
    pathname: PropTypes.string,
};

Component.defaultProps = {
    pathname: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
