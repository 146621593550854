//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React          from 'react';
import DropIn         from '@/components/connected/DropIn';
import LoadingOverlay from '@/components/connected/LoadingOverlay';
import MessageOverlay from '@/components/connected/MessageOverlay';
import Set            from '@/components/connected/Set';
import Sidebar        from '@/components/stateless/composed/Sidebar';
import { Component }  from 'react';
import styles         from './styles.module.scss';
import ConfirmOverlay from '@/components/connected/ConfirmOverlay';

class Screen extends Component {
    render() {
        return (
            <div className={styles.dashboard}>
                <Sidebar />
                <Set />
                <DropIn />
                <ConfirmOverlay />
                <MessageOverlay />
                <LoadingOverlay />
            </div>
        );
    }
}

export default (
    Screen
);
