//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update          from 'immutability-helper';
import { DropInTypes } from '@/store/actions/dropIn';

const initialState = {
    dropInOpen: false,
};

const closeOverlay = (action, state) => {
    return update(state, {
        dropInOpen: {
            $set: false,
        },
    });
};

const openOverlay = (action, state) => {
    return update(state, {
        dropInOpen: {
            $set: true,
        },
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case DropInTypes.CLOSE_OVERLAY: return closeOverlay(action, state);
        case DropInTypes.OPEN_OVERLAY:  return openOverlay(action, state);
        default:                        return state;
        // @formatter:on
    }
}
