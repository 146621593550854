//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const LoadingOverlayTypes = {
    PREPARATION_FINISHED:    'LoadingOverlay/PREPARATION_FINISHED',
    PREPARATION_PROGRESSED:  'LoadingOverlay/PREPARATION_PROGRESSED',
    PREPARATION_STARTED:     'LoadingOverlay/PREPARATION_STARTED',
    PREPARATION_STARTED_PRE: 'LoadingOverlay/PREPARATION_STARTED_PRE',
    UPLOAD_FINISHED:         'LoadingOverlay/UPLOAD_FINISHED',
    UPLOAD_STARTED:          'LoadingOverlay/UPLOAD_STARTED',
};

const preparationFinished = makeActionCreator(
    LoadingOverlayTypes.PREPARATION_FINISHED,
);

const preparationProgressed = makeActionCreator(
    LoadingOverlayTypes.PREPARATION_PROGRESSED,
    {
        timeNeededInSeconds: null,
    },
);

const preparationStarted = makeActionCreator(
    LoadingOverlayTypes.PREPARATION_STARTED,
    {
        buildingMenu: null,
        fileCount:    null,
    },
);

const preDownloadStarted = makeActionCreator(
    LoadingOverlayTypes.PREPARATION_STARTED_PRE,
);

const uploadFinished = makeActionCreator(
    LoadingOverlayTypes.UPLOAD_FINISHED,
);

const uploadStarted = makeActionCreator(
    LoadingOverlayTypes.UPLOAD_STARTED,
);

export const LoadingOverlayActions = {
    preparationFinished,
    preparationProgressed,
    preparationStarted,
    preDownloadStarted,
    uploadFinished,
    uploadStarted,
};
