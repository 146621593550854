//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import * as PropTypes  from 'prop-types';
import _               from 'lodash';
import ComponentHelper from '@/helper/ComponentHelper';
import I18n            from 'i18next';
import IconButton      from '@/components/stateless/atomic/IconButton';
import IconButtonTheme from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import moment          from 'moment';
import styles          from './styles.module.scss';

class LoadingOverlay extends React.Component {
    renderProgress = (progress) => {
        if (!this.props.isInPreDownload) {
            return (
                <div
                    className={styles.loadingOverlayProgressBar}
                    style={{ width: progress + '%' }}
                />
            );
        }

        return null;
    };

    renderProgressText = () => {
        if (this.props.isUpload) {
            return null;
        }

        if (this.props.buildingMenu) {
            return I18n.t('loadingProgressBuildingMenu');
        }

        if (this.props.isInPreDownload) {
            return I18n.t('loadingProgressPreLoad');
        }

        return I18n.t(
            'loadingProgress',
            {
                currentFile: this.props.currentFile,
                fileCount:   this.props.fileCount,
            },
        );
    };

    render() {
        if (this.props.fileCount || this.props.isInPreDownload) {
            const progress                   = this.props.currentFile / this.props.fileCount * 100;
            const timePerFileNeededInSeconds = this.props.timeNeededInSeconds / this.props.currentFile;
            const filesToProgress            = this.props.fileCount - this.props.currentFile;
            const timeLeftInSeconds          = filesToProgress * timePerFileNeededInSeconds;
            const timeLeftDuration           = moment.duration(timeLeftInSeconds * 1000);
            const title                      = this.props.isUpload ? I18n.t('upload') : I18n.t('download');

            moment.relativeTimeThreshold('ss', 60);
            moment.updateLocale(
                'de',
                {
                    relativeTime: {
                        s: function (number) {
                            return I18n.t(
                                'second_postfix',
                                {
                                    count: number,
                                },
                            );
                        },
                    },
                },
            );

            return (
                <div className={styles.loadingOverlayContainer}>
                    <div className={styles.loadingOverlay}>
                        <div className={styles.loadingOverlayText}>
                            <h2>
                                {title}
                            </h2>
                            <div className={styles.loadingOverlayProgressBarContainer}>
                                {this.renderProgress(progress)}

                            </div>
                            <div className={styles.loadingOverlayProgressBarTextContainer}>
                                <p>
                                    {this.renderProgressText()}
                                </p>
                                <p>
                                    {
                                        !this.props.timeNeededInSeconds ?
                                            I18n.t('loadingProgressTimeCalculating') :
                                            I18n.t(
                                                'loadingProgressTimeLeft',
                                                {
                                                    timeLeft: timeLeftDuration.humanize(),
                                                },
                                            )
                                    }
                                </p>
                            </div>
                            <div className={styles.loadingOverlayProgressBarButtonContainer}>
                                <IconButton
                                    text={I18n.t('abort')}
                                    theme={IconButtonTheme.transparent}
                                    onClick={this.props.abortFileExport}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = LoadingOverlay;

Component.propTypes = {
    abortFileExport:     PropTypes.func,
    buildingMenu:        PropTypes.bool,
    currentFile:         PropTypes.number,
    fileCount:           PropTypes.number,
    isInPreDownload:     PropTypes.bool,
    isUpload:            PropTypes.bool,
    timeNeededInSeconds: PropTypes.number,
};

Component.defaultProps = {
    abortFileExport:     _.noop,
    buildingMenu:        false,
    currentFile:         0,
    fileCount:           0,
    isInPreDownload:     false,
    isUpload:            false,
    timeNeededInSeconds: 0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
