//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React               from 'react';
import ComponentHelper     from '@/helper/ComponentHelper';
import styles              from './styles.module.scss';
import Button              from '@/components/stateless/atomic/Button';
import PropTypes           from '@/components/PropTypes';
import ExportPopupTheme    from '@/components/stateless/composed/ExportPopup/ExportPopupTheme';
import classNames          from 'classnames';
import ReactDOM            from 'react-dom';
import IconButton          from '@/components/stateless/atomic/IconButton';
import IconType            from '@/components/stateless/atomic/Icon/IconType';
import IconButtonTheme     from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import I18n                from 'i18next';
import ExportPopupPosition from '@/components/stateless/composed/ExportPopup/ExportPopupPosition';
import ExportPopupType     from '@/components/stateless/composed/ExportPopup/ExportPopupType';
import _                   from 'lodash';

class ExportPopup extends React.Component {
    constructor(props) {
        super(props);

        this.exportPopup = React.createRef();
        this.state       = {
            overlayOpen: false,
        };
    }

    onDownloadClicked = () => {
        switch (this.props.type) {
            case ExportPopupType.set:
                return this.props.onDownloadSetClicked();
            case ExportPopupType.sets:
                return this.props.onDownloadSetsClicked();
        }
    };

    onUploadClicked = () => {
        this.props.openOverlay();
    };

    handleSecondClick = (event) => {
        // We need to get the DOM node, because the component itself doesn't have the contains function.
        const exportPopupNode = ReactDOM.findDOMNode(this.exportPopup.current);

        if (!exportPopupNode.contains(event.target)) {
            this.onExportPopupClicked();
        }
    };

    onExportPopupClicked = () => {
        const overlayOpen = !this.state.overlayOpen;

        this.setState({
            overlayOpen,
        });

        if (overlayOpen) {
            document.addEventListener('mousedown', this.handleSecondClick, false);
        } else {
            document.removeEventListener('mousedown', this.handleSecondClick, false);
        }
    };

    renderExportButton = (count) => {
        const { setsCount, filesCount } = this.props;

        if (
            setsCount &&
            (
                !count ||
                filesCount
            )
        ) {
            return (
                <IconButton
                    iconType={IconType.download}
                    theme={IconButtonTheme.transparent}
                    text={I18n.t(
                        'setListExport',
                        {
                            count,
                        },
                    )}
                    onClick={this.onDownloadClicked}
                />
            );
        }
    };

    renderOverlay = () => {
        if (this.state.overlayOpen) {
            const count = this.props.type === ExportPopupType.sets ? 0 : 1;

            return (
                <div className={styles.exportPopupOverlay}>
                    <div className={styles.exportPopupOverlayContent}>
                        {this.renderExportButton(count)}
                        <IconButton
                            iconType={IconType.upload}
                            theme={IconButtonTheme.transparent}
                            text={I18n.t(
                                'setListImport',
                                {
                                    count,
                                },
                            )}
                            onClick={this.onUploadClicked}
                        />
                    </div>
                </div>
            );
        }

        return null;
    };

    render() {
        return (
            <div
                ref={this.exportPopup}
                className={classNames(
                    styles.exportPopupContainer,
                    {
                        [styles.exportPopupContainerRed]:    this.props.theme === ExportPopupTheme.red,
                        [styles.exportPopupContainerTop]:    this.props.position === ExportPopupPosition.top,
                        [styles.exportPopupContainerBottom]: this.props.position === ExportPopupPosition.bottom,
                    },
                )}
            >
                <Button
                    text={'•••'}
                    onClick={this.onExportPopupClicked}
                />
                {this.renderOverlay()}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ExportPopup;

Component.propTypes = {
    filesCount:            PropTypes.number,
    onDownloadSetClicked:  PropTypes.func,
    onDownloadSetsClicked: PropTypes.func,
    openOverlay:           PropTypes.func,
    position:              PropTypes.oneOf(Object.values(ExportPopupPosition)),
    setsCount:             PropTypes.number,
    theme:                 PropTypes.oneOf(Object.values(ExportPopupTheme)),
    type:                  PropTypes.oneOf(Object.values(ExportPopupType)),
};

Component.defaultProps = {
    filesCount:            0,
    onDownloadSetClicked:  _.noop,
    onDownloadSetsClicked: _.noop,
    openOverlay:           _.noop,
    position:              ExportPopupPosition.bottom,
    setsCount:             0,
    theme:                 ExportPopupTheme.default,
    type:                  null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'overlayOpen',
];

export default Component;
