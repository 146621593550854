//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper from '@/helper/ComponentHelper';
import Link            from '../Link';
import PropTypes       from '@/components/PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class HeaderButton extends React.Component {
    render () {
        if (this.props.route) {
            return (
                <div className={styles.headerButton}>
                    <Link
                        to={this.props.route}
                        search={this.props.search}
                        hash={this.props.hash}
                    >
                        {this.props.label}
                    </Link>
                </div>
            );
        }

        return null;
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = HeaderButton;

Component.propTypes = {
    hash:   PropTypes.string,
    icon:   PropTypes.any,
    route:  PropTypes.string,
    search: PropTypes.object,
};

Component.defaultProps = {
    hash:   '',
    icon:   null,
    route:  '',
    search: {},
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
