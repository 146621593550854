//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper                from '@/helper/ComponentHelper';
import PropTypes                      from '@/components/PropTypes';
import React                          from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';

class Link extends React.Component {
    getToParameter = () => {
        const pathname     = this.props.to;
        const search       = Object.entries(this.props.search).map(([key, value]) => `${key}=${value}`).join('&');
        const hash         = this.props.hash;
        const toParameters = {
            pathname: pathname,
            search:   search ? '?' + search : '',
            hash:     hash ? '#' + hash : '',
        };

        return toParameters;
    };

    render () {
        return (
            <ReactRouterDomLink
                className={this.props.className}
                to={this.getToParameter()}
                target={this.props.target}
            >
                {this.props.children}
            </ReactRouterDomLink>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Link;

Component.propTypes = {
    children:  PropTypes.children,
    className: PropTypes.string,
    hash:      PropTypes.string,
    search:    PropTypes.object,
    target:    PropTypes.string,
    to:        PropTypes.string,
};

Component.defaultProps = {
    children:  [],
    className: '',
    hash:      '',
    search:    {},
    target:    null,
    to:        null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
