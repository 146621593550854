//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes            from 'prop-types';
import ComponentHelper           from '@/helper/ComponentHelper';
import React                     from 'react';
import StatelessConfirmOverlay   from '@/components/stateless/atomic/ConfirmOverlay';
import { bindActionCreators }    from 'redux';
import { connect }               from 'react-redux';
import { ConfirmOverlayActions } from '@/store/actions/confirmOverlay';

class ConfirmOverlay extends React.Component {
    render() {
        return (
            <StatelessConfirmOverlay
                confirmOverlayOpen={this.props.confirmOverlayOpen}
                closeConfirmOverlayClicked={this.closeConfirmOverlayClicked}
                importIntoActiveSetClicked={this.importIntoActiveSetClicked}
                importIntoNewSetClicked={this.importIntoNewSetClicked}
                title={this.props.title}
                text={this.props.text}
            />
        );
    }

    closeConfirmOverlayClicked = () => {
        this.props.closeOverlay();
    };

    importIntoActiveSetClicked = () => {
        this.props.importIntoActiveSet();
        this.closeConfirmOverlayClicked();
    };

    importIntoNewSetClicked = () => {
        this.props.importIntoNewSet();
        this.closeConfirmOverlayClicked();
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ConfirmOverlay;

Component.propTypes = {
    confirmOverlayOpen: PropTypes.bool,
    text:               PropTypes.string,
    title:              PropTypes.string,
};

Component.defaultProps = {
    confirmOverlayOpen: false,
    text:               null,
    title:              null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        confirmOverlayOpen: state.confirmOverlay.confirmOverlayOpen,
        text:               state.confirmOverlay.text,
        title:              state.confirmOverlay.title,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators({
    ...ConfirmOverlayActions,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
