//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper from '@/helper/ComponentHelper';
import IconChevron     from '../../../../assets/icons/chevron.svg';
import IconCross       from '../../../../assets/icons/cross.svg';
import IconDownload    from '../../../../assets/icons/download.svg';
import IconUpload      from '../../../../assets/icons/upload.svg';
import IconMinusCircle from '../../../../assets/icons/minusCircle.svg';
import IconPlusCircle  from '../../../../assets/icons/plusCircle.svg';
import IconType        from './IconType';
import PropTypes       from '@/components/PropTypes';
import React           from 'react';
import SelectionHelper from '@/helper/SelectionHelper';
import styles          from './styles.module.scss';

class Icon extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render() {
        return (
            <span className={styles.icon}>
                {this.renderSvg()}
            </span>
        );
    }

    renderSvg = () => {
        return SelectionHelper.get(
            this.props.iconType,
            {
                [IconType.plusCircle]:  <IconPlusCircle />,
                [IconType.minusCircle]: <IconMinusCircle />,
                [IconType.chevron]:     <IconChevron />,
                [IconType.download]:    <IconDownload />,
                [IconType.upload]:      <IconUpload />,
                [IconType.cross]:       <IconCross />,
            },
        );
    };
}

const Component = Icon;

Component.propTypes = {
    iconType: PropTypes.oneOf(Object.values(IconType)),
};

Component.defaultProps = {
    iconType: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
