//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                from 'lodash';
import packageJson      from '../../package.json';
import { initDB }       from 'react-indexed-db';
import { useIndexedDB } from 'react-indexed-db';

const StoreName = 'file';

const DBConfig = {
    name:             packageJson.name,
    version:          1,
    objectStoresMeta: [
        {
            store:       StoreName,
            storeConfig: {
                keyPath:       'id',
                autoIncrement: true,
            },
            storeSchema: [
                {
                    name:    'file',
                    keypath: 'id',
                    options: { unique: false },
                },
            ],
        },
    ],
};

class FileStorage {
    static useIndexedDB = {};

    static saveFile(file) {
        return this.useIndexedDB.add({ file });
    }

    static loadFile(id) {
        return this.useIndexedDB.getByID(id);
    }

    static deleteFile(id) {
        return this.useIndexedDB.deleteRecord(id);
    }

    static initializeStore() {
        initDB(DBConfig);

        this.useIndexedDB = useIndexedDB(StoreName);

        console.log('FileStorage: this.useIndexedDB', this.useIndexedDB);
    }

    static clear() {
        return this.useIndexedDB.clear();
    }

    static dumpStore() {
        return this.useIndexedDB.getAll();
    }

    static loadFilesFromStoreByIds(files, callback = _.noop) {
        const loadedFiles = [];

        if (files.length === 0) {
            callback(loadedFiles);
        }

        files.forEach(
            (file) => {
                FileStorage.loadFile(file.fileId).then(
                    (data) => {
                        if (data) {
                            file.data = data.file;

                            loadedFiles.push(file);
                        }

                        if (loadedFiles.length === files.length) {
                            callback(loadedFiles);
                        }
                    },
                ).catch(
                    (error) => {
                        console.log('FileUpload: loadFile ', error);
                    },
                );
            },
        );
    }
}

export default FileStorage;
