//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const SetTypes = {
    ABORT_DOWNLOAD:    'Set/ABORT_DOWNLOAD',
    ADD_FILES:         'Set/ADD_FILES',
    CHANGE_ACTIVE_SET: 'Set/CHANGE_ACTIVE_SET',
    CONVERT_SET:       'Set/CONVERT_SET',
    DELETE_FILE:       'Set/DELETE_FILE',
    DELETE_SET:        'Set/DELETE_SET',
    DOWNLOAD_SET:      'Set/DOWNLOAD_SET',
    DOWNLOAD_SETS:     'Set/DOWNLOAD_SETS',
    MOVE_FILE_DOWN:    'Set/MOVE_FILE_DOWN',
    MOVE_FILE_UP:      'Set/MOVE_FILE_UP',
    NEW_SET:           'Set/NEW_SET',
    RENAME_SET:        'Set/RENAME_SET',
    RENAME_SET_FILE:   'Set/RENAME_SET_FILE',
    TRY_ADD_FILES:     'Set/TRY_ADD_FILES',
};

const abortDownload = makeActionCreator(
    SetTypes.ABORT_DOWNLOAD,
);

const addFiles = makeActionCreator(
    SetTypes.ADD_FILES,
    {
        files: null,
    },
);

const tryAddFiles = makeActionCreator(
    SetTypes.TRY_ADD_FILES,
    {
        pdfFiles: null,
        zipFiles: null,
    },
);

const changeActiveSet = makeActionCreator(
    SetTypes.CHANGE_ACTIVE_SET,
    {
        activeSetIndex: null,
    },
);

const convertSet = makeActionCreator(
    SetTypes.CONVERT_SET,
    {
        files: null,
    },
);

const deleteFile = makeActionCreator(
    SetTypes.DELETE_FILE,
    {
        fileIndex: null,
    },
);

const deleteSet = makeActionCreator(
    SetTypes.DELETE_SET,
);

const downloadSet = makeActionCreator(
    SetTypes.DOWNLOAD_SET,
);

const downloadSets = makeActionCreator(
    SetTypes.DOWNLOAD_SETS,
);

const moveFileDown = makeActionCreator(
    SetTypes.MOVE_FILE_DOWN,
    {
        fileIndex: null,
    },
);

const moveFileUp = makeActionCreator(
    SetTypes.MOVE_FILE_UP,
    {
        fileIndex: null,
    },
);

const newSet = makeActionCreator(
    SetTypes.NEW_SET,
    {
        name: null,
    },
);

const renameSet = makeActionCreator(
    SetTypes.RENAME_SET,
    {
        name: null,
    },
);

const renameSetFile = makeActionCreator(
    SetTypes.RENAME_SET_FILE,
    {
        name:      null,
        fileIndex: null,
    },
);

export const SetActions = {
    abortDownload,
    addFiles,
    changeActiveSet,
    convertSet,
    deleteFile,
    deleteSet,
    downloadSet,
    downloadSets,
    moveFileDown,
    moveFileUp,
    newSet,
    renameSet,
    renameSetFile,
    tryAddFiles,
};
