//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes  from 'prop-types';
import _               from 'lodash';
import ComponentHelper from '@/helper/ComponentHelper';
import IconButton      from '@/components/stateless/atomic/IconButton';
import IconButtonTheme from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import React           from 'react';
import styles          from './styles.module.scss';
import Button          from '@/components/stateless/atomic/Button';
import I18n            from 'i18next';
import ButtonTheme     from '@/components/stateless/atomic/Button/ButtonTheme';

class ConfirmOverlay extends React.Component {
    closeConfirmOverlayClicked = () => {
        this.props.closeConfirmOverlayClicked();
    };

    importIntoActiveSetClicked = () => {
        this.props.importIntoActiveSetClicked();
    };

    importIntoNewSetClicked = () => {
        this.props.importIntoNewSetClicked();
    };

    render() {
        if (this.props.confirmOverlayOpen) {
            return (
                <div className={styles.confirmOverlayContainer}>
                    <div className={styles.confirmOverlay}>
                        <div className={styles.confirmOverlayCloseContainer}>
                            <IconButton
                                iconType={IconType.cross}
                                theme={IconButtonTheme.transparent}
                                onClick={this.closeConfirmOverlayClicked}
                            />
                        </div>
                        <div className={styles.confirmOverlayText}>
                            <h2>
                                {this.props.title}
                            </h2>
                            <p>
                                {this.props.text}
                            </p>
                        </div>
                        <div className={styles.confirmOverlayButtons}>
                            <Button
                                text={I18n.t('newSetChoice')}
                                theme={ButtonTheme.red}
                                onClick={this.importIntoNewSetClicked}
                            />
                            <Button
                                text={I18n.t('activeSetChoice')}
                                theme={ButtonTheme.default}
                                onClick={this.importIntoActiveSetClicked}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ConfirmOverlay;

Component.propTypes = {
    closeConfirmOverlayClicked: PropTypes.func,
    confirmOverlayOpen:         PropTypes.bool,
    importIntoActiveSetClicked: PropTypes.func,
    importIntoNewSetClicked:    PropTypes.func,
    text:                       PropTypes.string,
    title:                      PropTypes.string,
};

Component.defaultProps = {
    closeConfirmOverlayClicked: _.noop,
    confirmOverlayOpen:         false,
    importIntoActiveSetClicked: _.noop,
    importIntoNewSetClicked:    _.noop,
    text:                       null,
    title:                      null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
