//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import ComponentHelper        from '@/helper/ComponentHelper';
import PropTypes              from '@/components/PropTypes';
import ExportPopupTheme       from '@/components/stateless/composed/ExportPopup/ExportPopupTheme';
import ExportPopupPosition    from '@/components/stateless/composed/ExportPopup/ExportPopupPosition';
import ExportPopupType        from '@/components/stateless/composed/ExportPopup/ExportPopupType';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import _                      from 'lodash';
import { SetActions }         from '@/store/actions/set';
import StatelessExportPopup   from '@/components/stateless/composed/ExportPopup';
import { DropInActions }      from '@/store/actions/dropIn';

class ExportPopup extends React.Component {
    render() {
        const filesCount = (
            this.props.activeSet ?
                this.props.activeSet.files.length :
                0
        );

        return (
            <StatelessExportPopup
                position={this.props.position}
                theme={this.props.theme}
                type={this.props.type}
                filesCount={filesCount}
                setsCount={this.props.setsCount}
                openOverlay={this.props.openOverlay}
                onDownloadSetClicked={this.props.downloadSet}
                onDownloadSetsClicked={this.props.downloadSets}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ExportPopup;

Component.propTypes = {
    activeSet:    PropTypes.object,
    setsCount:    PropTypes.number,
    downloadSet:  PropTypes.func,
    downloadSets: PropTypes.func,
    position:     PropTypes.oneOf(Object.values(ExportPopupPosition)),
    theme:        PropTypes.oneOf(Object.values(ExportPopupTheme)),
    type:         PropTypes.oneOf(Object.values(ExportPopupType)),
};

Component.defaultProps = {
    activeSet:    null,
    setsCount:    0,
    downloadSet:  _.noop,
    downloadSets: _.noop,
    position:     ExportPopupPosition.bottom,
    theme:        ExportPopupTheme.default,
    type:         null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => {
    const { set }  = state;
    const { sets } = set;

    return {
        activeSet: sets[set.activeSetIndex],
        setsCount: sets.length,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    SetActions,
    DropInActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
