//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes      from 'prop-types';
import _                   from 'lodash';
import classNames          from 'classnames';
import ComponentHelper     from '@/helper/ComponentHelper';
import DebounceInput       from 'react-debounce-input';
import I18n                from 'i18next';
import IconButton          from '@/components/stateless/atomic/IconButton';
import IconButtonTheme     from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import IconType            from '@/components/stateless/atomic/Icon/IconType';
import Label               from '@/components/stateless/atomic/Label';
import LabelSize           from '@/components/stateless/atomic/Label/LabelSize';
import LabelTheme          from '@/components/stateless/atomic/Label/LabelTheme';
import React               from 'react';
import ReactDOM            from 'react-dom';
import styles              from './styles.module.scss';
import ExportPopupTheme    from '@/components/stateless/composed/ExportPopup/ExportPopupTheme';
import ExportPopupPosition from '@/components/stateless/composed/ExportPopup/ExportPopupPosition';
import ExportPopupType     from '@/components/stateless/composed/ExportPopup/ExportPopupType';
import ExportPopup         from '@/components/connected/ExportPopup';
import Tooltip             from '@/components/connected/Tooltip';
import TooltipPosition     from '@/components/stateless/atomic/Tooltip/TooltipPosition';

class SetDetail extends React.Component {
    deleteCheck              = null;
    nameInputDebounceTimeout = 800;

    constructor(props) {
        super(props);

        this.state = {
            deleteSet: false,
        };
    }

    render() {
        const value = (
            this.props.name !== I18n.t('setNamePlaceholder') ?
                this.props.name :
                ''
        );

        return (
            <div className={styles.setDetailContainer}>
                <div className={styles.setDetailHeader}>
                    <Label
                        text={I18n.t('setDetails')}
                        theme={LabelTheme.dark}
                    />
                </div>
                <div className={styles.setDetail}>
                    <Label
                        text={I18n.t('name')}
                        theme={LabelTheme.darkLight}
                    />
                    <DebounceInput
                        value={value}
                        placeholder={I18n.t('setNamePlaceholder')}
                        debounceTimeout={this.nameInputDebounceTimeout}
                        onChange={this.onNameChange}
                    />
                    <div className={styles.setDetailDownloadContainer}>
                        <Label
                            text={I18n.t('sonatisFile')}
                            theme={LabelTheme.dark}
                            size={LabelSize.small}
                        />
                        <IconButton
                            iconType={IconType.download}
                            theme={IconButtonTheme.red}
                            text={I18n.t('download')}
                            onClick={this.downloadSetClicked}
                            disabled={!this.props.fileCount}
                            disabledText={I18n.t('emptySet')}
                        />
                    </div>
                </div>
                <div
                    className={classNames(
                        styles.setDetailFooter,
                        {
                            [styles.deleteCheck]: this.state.deleteSet,
                        },
                    )}
                >
                    <Label
                        text={I18n.t('set')}
                        theme={LabelTheme.dark}
                        size={LabelSize.small}
                    >
                        <ExportPopup
                            theme={ExportPopupTheme.red}
                            position={ExportPopupPosition.top}
                            type={ExportPopupType.set}
                        />
                        <Tooltip
                            identifier={'setExport'}
                            text={I18n.t('tooltipSetExport')}
                            top={'calc(100% - 230px)'}
                            right={630}
                            position={TooltipPosition.bottom}
                        />
                    </Label>
                    {
                        !this.state.deleteSet ? (
                            <IconButton
                                iconType={IconType.minusCircle}
                                text={I18n.t('setDelete')}
                                onClick={this.setDeleteSet}
                            />
                        ) : (
                            <IconButton
                                ref={this.setDeleteCheckRef}
                                iconType={IconType.minusCircle}
                                text={I18n.t('setDeleteCheck')}
                                theme={IconButtonTheme.red}
                                onClick={this.deleteSetConfirmed}
                            />
                        )
                    }
                </div>
            </div>
        );
    }

    downloadSetClicked = () => {
        this.props.preDownloadStarted();
        this.props.onDownloadFilesClicked();
    };

    setDeleteCheckRef = (deleteRef) => {
        this.deleteCheck = deleteRef;
    };

    onNameChange = (event) => {
        this.props.onNameChange({
            name: event.target.value,
        });
    };

    handleSecondDeleteClick = (event) => {
        // We need to get the DOM node, because the component itself doesn't have the contains function.
        const deleteCheck = ReactDOM.findDOMNode(this.deleteCheck);

        if (!deleteCheck.contains(event.target)) {
            this.unsetDeleteSet();
        }
    };

    deleteSetConfirmed = () => {
        this.unsetDeleteSet();
        this.props.onDeleteSetClicked();
    };

    setDeleteSet = () => {
        this.setState({
            deleteSet: true,
        });

        document.addEventListener('mousedown', this.handleSecondDeleteClick, false);
    };

    unsetDeleteSet = () => {
        this.setState({
            deleteSet: false,
        });

        document.removeEventListener('mousedown', this.handleSecondDeleteClick, false);
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    componentWillUnmount() {
        this.unsetDeleteSet();
    };
}

const Component = SetDetail;

Component.propTypes = {
    preparationFinished:     PropTypes.func,
    fileCount:               PropTypes.number,
    name:                    PropTypes.string,
    onDeleteSetClicked:      PropTypes.func,
    onDownloadButtonClicked: PropTypes.func,
    onDownloadFilesClicked:  PropTypes.func,
    onNameChange:            PropTypes.func,
    preDownloadStarted:      PropTypes.func,
};

Component.defaultProps = {
    preparationFinished:     _.noop,
    fileCount:               0,
    name:                    '',
    onDeleteSetClicked:      _.noop,
    onDownloadButtonClicked: _.noop,
    onDownloadFilesClicked:  _.noop,
    onNameChange:            _.noop,
    preDownloadStarted:      _.noop,
};

Component.renderAffectingProps = [
    'fileCount',
    'name',
];

Component.renderAffectingStates = [
    'deleteSet',
];

export default Component;
