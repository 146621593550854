//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                  from 'immutability-helper';
import { LoadingOverlayTypes } from '@/store/actions/loadingOverlay';

const initialState = {
    buildingMenu:        false,
    currentFile:         0,
    fileCount:           0,
    isInPreDownload:     false,
    isUpload:            false,
    timeNeededInSeconds: 0,
};

const preparationFinished = (action, state) => {
    return initialState;
};

const preparationProgressed = (action, state) => {
    return update(state, {
        currentFile:         {
            $increaseBy: 1,
        },
        timeNeededInSeconds: {
            $increaseBy: action.timeNeededInSeconds,
        },
    });
};

const preparationStarted = (action, state) => {
    return update(state, {
        buildingMenu:        {
            $set: action.buildingMenu,
        },
        isUpload:            {
            $set: false,
        },
        currentFile:         {
            $set: 0,
        },
        fileCount:           {
            $set: action.fileCount,
        },
        timeNeededInSeconds: {
            $set: 0,
        },
        isInPreDownload:     {
            $set: false,
        },
    });
};

const preDownloadStarted = (action, state) => {
    return update(state, {
        isInPreDownload: {
            $set: true,
        },
    });
};

const uploadFinished = () => {
    return initialState;
};

const uploadStarted = (action, state) => {
    return update(state, {
        buildingMenu:        {
            $set: false,
        },
        currentFile:         {
            $set: 0,
        },
        isUpload:            {
            $set: true,
        },
        fileCount:           {
            $set: 1,
        },
        timeNeededInSeconds: {
            $set: 0,
        },
        isInPreDownload:     {
            $set: false,
        },
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case LoadingOverlayTypes.PREPARATION_FINISHED:    return preparationFinished(action, state);
        case LoadingOverlayTypes.PREPARATION_PROGRESSED:  return preparationProgressed(action, state);
        case LoadingOverlayTypes.PREPARATION_STARTED:     return preparationStarted(action, state);
        case LoadingOverlayTypes.PREPARATION_STARTED_PRE: return preDownloadStarted(action, state);
        case LoadingOverlayTypes.UPLOAD_FINISHED:         return uploadFinished(action, state);
        case LoadingOverlayTypes.UPLOAD_STARTED:          return uploadStarted(action, state);
        default:                                          return state;
        // @formatter:on
    }
}
