//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes  from 'prop-types';
import ComponentHelper from '@/helper/ComponentHelper';
import I18n            from 'i18next';
import React           from 'react';
import SetList         from '@/components/connected/SetList';
import SidebarHeader   from '@/components/connected/SidebarHeader';
import styles          from './styles.module.scss';
import Tooltip         from '@/components/connected/Tooltip';
import TooltipPosition from '@/components/stateless/atomic/Tooltip/TooltipPosition';
import VersionNumber   from '@/components/stateless/atomic/VersionNumber';

class Sidebar extends React.Component {
    render() {
        return (
            <div className={styles.sidebarContainer}>
                <div className={styles.sidebarScrollContainer}>
                    <SidebarHeader />
                    <SetList sets={this.props.sets} />
                    <Tooltip
                        identifier={'setList'}
                        text={I18n.t('tooltipNewSet')}
                        top={40}
                        left={'calc(100% + 10px)'}
                        position={TooltipPosition.left}
                    />
                    <Tooltip
                        identifier={'setListExport'}
                        text={I18n.t('tooltipSetListExport')}
                        top={40}
                        left={160}
                        position={TooltipPosition.left}
                    />
                </div>
                <div className={styles.sidebarVersionNumber}>
                    <VersionNumber />
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Sidebar;

Component.propTypes = {
    sets: PropTypes.array,
};

Component.defaultProps = {
    sets: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
