//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PDFJS                from 'pdfjs-dist';
import * as PropTypes            from 'prop-types';
import _                         from 'lodash';
import ComponentHelper           from '@/helper/ComponentHelper';
import PDFJSWorker               from 'pdfjs-dist/build/pdf.worker.entry.js';
import React                     from 'react';
import StatelessSetDetail        from '@/components/stateless/composed/SetDetail';
import { bindActionCreators }    from 'redux';
import { connect }               from 'react-redux';
import { SetActions }            from '@/store/actions/set';
import { LoadingOverlayActions } from '@/store/actions/loadingOverlay';

PDFJS.GlobalWorkerOptions.workerSrc = PDFJSWorker;

class SetDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            epdFiles: [],
        };
    }

    render() {
        return (
            <StatelessSetDetail
                name={this.props.name}
                onDeleteSetClicked={this.onDeleteSetClick}
                onNameChange={this.onNameChange}
                onDownloadFilesClicked={this.onDownloadFilesClicked}
                fileCount={this.props.files.length}
                preparationFinished={this.props.preparationFinished}
                preDownloadStarted={this.props.preDownloadStarted}
            />
        );
    }

    onNameChange = (name) => {
        this.props.renameSet(name);
    };

    onDeleteSetClick = () => {
        this.props.deleteSet();
    };

    onDownloadFilesClicked = async () => {
        const files = this.props.files;

        this.props.convertSet({ files });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SetDetail;

Component.propTypes = {
    activeSetIndex:      PropTypes.number,
    preparationFinished: PropTypes.func,
    fileCount:           PropTypes.number,
    files:               PropTypes.array,
    name:                PropTypes.string,
    preDownloadStarted:  PropTypes.func,
};

Component.defaultProps = {
    activeSetIndex:      0,
    preparationFinished: _.noop,
    fileCount:           0,
    files:               [],
    name:                '',
    preDownloadStarted:  _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        activeSetIndex: state.set.activeSetIndex,
        fileCount:      state.loadingOverlay.fileCount,
        files:          state.set.sets[state.set.activeSetIndex].files,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    SetActions,
    LoadingOverlayActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
