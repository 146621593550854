//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes  from 'prop-types';
import _               from 'lodash';
import classNames      from 'classnames';
import ComponentHelper from '@/helper/ComponentHelper';
import I18n            from 'i18next';
import Icon            from '@/components/stateless/atomic/Icon';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import Label           from '@/components/stateless/atomic/Label';
import React           from 'react';
import styles          from './styles.module.scss';

class SetList extends React.Component {
    render () {
        return this.props.sets.map(this.renderSet);
    }

    getSetLabel = (set, index) => {
        return `${index + 1}. ${set.name}`;
    };

    renderSet = (set, index) => {
        return (
            <div
                key={'set-' + index}
                className={classNames(
                    styles.setItemContainer,
                    {
                        [styles.active]: this.props.activeSetIndex === index,
                    },
                )}
                onClick={() => this.setClicked({ activeSetIndex: index })}
            >
                <div className={styles.setItemTitleContainer}>
                    <Label
                        text={this.getSetLabel(set, index)}
                        grow={true}
                    />
                    <Icon iconType={IconType.chevron} />
                </div>
                <span className={styles.setItemFilesCount}>
                    {set.files.length} {I18n.t('files')}
                </span>
            </div>
        );
    };

    setClicked = (activeSetIndex) => {
        this.props.setOnClick(activeSetIndex);
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SetList;

Component.propTypes = {
    activeSetIndex: PropTypes.number,
    setOnClick:     PropTypes.func,
    sets:           PropTypes.array,
};

Component.defaultProps = {
    activeSetIndex: null,
    setOnClick:     _.noop,
    sets:           [],
};

Component.renderAffectingProps = [
    'activeSetIndex',
    'sets',
];

Component.renderAffectingStates = [];

export default Component;
