//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Environment          from '@/helper/Environment';
import german               from './german';
import I18n                 from 'i18next';
import LanguageHelper       from '@/helper/Language';
import Languages            from '@/constants/Languages';
import { initReactI18next } from 'react-i18next';

const resources = {
    [Languages.german]: {
        translation: german,
    },
};

I18n.use(initReactI18next)
    .init({
        resources,
        lng:           LanguageHelper.getDefaultLanguage(),
        fallbackLng:   Languages.german,
        debug:         Environment.isDevelopment(),
        interpolation: {
            escapeValue: false,
        },
    })
;

export default I18n;
