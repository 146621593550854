//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const german = {
    abort:                                    'Abbrechen',
    activeSetChoice:                          'In aktives Set importieren',
    addToSet:                                 'Set oder Dateien hinzufügen',
    addToSetFileNameHint:                     'Achtung: Die Länge eines Dateinamens ist auf maximal 70 Zeichen begrenzt.\nEs ist nicht möglich ZIP-Dateien zu importieren, die nicht mit der Sonatis-App exportiert wurden.',
    addToSetText:                             'Lassen Sie Ihre PDF-Datei hier fallen um Sie dem aktuellen Set hinzuzufügen.\nAlternativ können Sie ZIP-Dateien fallen lassen um ein oder mehrere Sets hinzuzufügen.',
    alertBoxErrorText:                        'Fehler',
    alertBoxSuccessText:                      'Erfolg',
    allRight:                                 'Alles Klar!',
    back:                                     'Zurück',
    couldNotAddAllFilesToSet:                 'Es konnten nicht alle Dateien zum Set hinzugefügt werden',
    deleteFile:                               'Datei löschen',
    deleteFileCheck:                          'Soll diese Datei wirklich gelöscht werden?',
    download:                                 'Downloaden',
    downloading:                              'Export in Vorbereitung...',
    emptySet:                                 'Leeres Set',
    error_404:                                '404',
    files:                                    'Dateien',
    filesInSet:                               'Dateien im Set:',
    followingFilesAlreadyExist:               'Die folgenden Dateien existieren bereits in diesem Set "{{names}}"',
    followingFilesAlreadyExistMany:           '{{count}} Dateien existieren bereits in diesem Set und wurden übersprungen.',
    goToDashboard:                            'Zum Dashboard',
    home:                                     'Home',
    importIntoExistingSet:                    'In aktives Set importieren?',
    imprint:                                  'Impressum',
    inventory:                                'Inventar',
    inventoryDetails:                         'Inventar-Details',
    itemNotFound:                             'Eintrag nicht gefunden',
    loading:                                  'Lade...',
    loadingInventory:                         'Lade Inventarliste...',
    loadingLogin:                             'Prüfe Zugangsdaten...',
    loadingProgress:                          '{{currentFile}} von {{fileCount}} Seiten wurden verarbeitet.',
    loadingProgressBuildingMenu:              'Einen Moment noch, das Menü wird generiert.',
    loadingProgressPreLoad:                   'Einen Moment noch, der Export wird vorbereitet.',
    loadingProgressTimeCalculating:           'Verbleibende Zeit wird berechnet...',
    loadingProgressTimeLeft:                  'Etwa {{timeLeft}} verbleibend',
    login:                                    'Login',
    loginError:                               'Der Login ist leider fehlgeschlagen. Bitte überprüfen Sie Ihre Zugangsdaten und versuchen es erneut.',
    management:                               'Verwaltung',
    name:                                     'Name',
    newSetChoice:                             'Neues Set anlegen',
    newVersionHintLaterButton:                'Later',
    newVersionHintText:                       'A new version of this application is available, please update now.',
    newVersionHintUpdateButton:               'Update',
    noFiles:                                  'Keine Dateien im Set',
    noFilesMessage:                           '', // TODO: https://lulububu.atlassian.net/browse/SONATIS-87
    pageNotFound:                             'Die Seite wurde nicht gefunden.',
    pageTitle:                                'Sonatis',
    privacy:                                  'Datenschutz',
    rangeCombinerFrom:                        'von',
    rangeCombinerShort:                       ' - ',
    rangeCombinerTo:                          'bis',
    reloadData:                               'Daten neu laden',
    screenDesign:                             'Screen Design',
    screenTooSmallHint:                       'Vergrößern sie einfach die Anwendung wieder ein wenig und schon klappt es.',
    screenTooSmallText:                       'Hoppla, Sie haben sicherlich versucht, die Anwendung auf Ihrem Bildschirm zu verkleinern um bequem Dateien aus dem Explorer in die Anwendung hinein zu ziehen.',
    screenTooSmallTitle:                      'Ihr Display oder Browserfenster ist zu klein.',
    second_postfix:                           '{{count}} Sekunde',
    second_postfix_plural:                    '{{count}} Sekunden',
    set:                                      'Set',
    setDelete:                                'Set löschen',
    setDeleteCheck:                           'Set wirkich löschen?',
    setDetails:                               'Set-Details',
    setListExport:                            'Set-Liste exportieren',
    setListExport_plural:                     'Set-Listen exportieren',
    setListImport:                            'Set-Liste importieren',
    setListImport_plural:                     'Set-Listen importieren',
    setNamePlaceholder:                       'Set-Name',
    shouldTheZipBeImportedIntoTheExistingSet: 'Soll die ZIP-Datei in das aktive Set importiert werden, oder ein neues Set erstellt werden?',
    sonatisFile:                              'Sonatis-Datei',
    sonatisLogo:                              'Sonatis Logo',
    tooltipNewFiles:                          'Drücken Sie auf das + um eine oder mehrere Dateien (Noten) für eine Setliste zusammen zu stellen.',
    tooltipNewSet:                            'Set-Listen können mehrere Dateien (Noten) beinhalten. Klicken Sie auf das + um eine neue Set-Liste zu erstellen.',
    tooltipSetExport:                         'Drücken Sie auf die drei Punkte um das aktive Sets zu exportieren.',
    tooltipSetListExport:                     'Drücken Sie auf die drei Punkte um alle Sets zu exportieren.',
    upload:                                   'Hochladen',
    welcome:                                  'Herzlich Willkommen',
    welcomeMessage:                           'Liebe Kunden und Kundinnen von Sonatis. Es ist schön, Sie auf unserer Webseite begrüßen zu dürfen. Machen sie sich vertraut mit der Anwendung. Wir haben die Funktionalität auf das Wesentliche beschränkt, so dass Sie möglichst schnell Ihr Sonatis nutzen können.',
    windowSize:                               'Fenstergröße',
    yourSetList:                              'Ihre Set-Liste',
    zipNotCreatedBySonatis:                   'Inkompatible ZIP-Datei erkannt',
    zipNotCreatedBySonatisText:               'Bitte entpacken Sie die ZIP-Datei und ziehen Sie die PDF-Dateien in die Sonatis-App.',
    zipNotCreatedBySonatisHint:               'Es ist nicht möglich ZIP-Dateien zu importieren, die nicht mit der Sonatis-App exportiert wurden.',
    //
    // The following entries are mandatory
    //
    and:                     'und',
    more:                    'Mehr',
    no:                      'Nein',
    oclock:                  'Uhr',
    or:                      'oder',
    versionNumberDateFormat: 'DD.MM.YYYY HH:mm:ss',
    yes:                     'Ja',
};

module.exports = german;
