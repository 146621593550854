//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import JSZip      from 'jszip';
import FileStore  from '@/helper/FileStore';
import Pdf        from '@/helper/Pdf';
import FileUpload from '@/helper/FileUpload';
import _          from 'lodash';

class Zip {
    static create = (files, originalFiles, callback) => {
        const zip = new JSZip();

        for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
            const page = files[fileIndex];

            zip.file(
                page.fileName,
                page.data,
                {
                    binary: true,
                },
            );
        }

        zip.file(
            'sonatis-export-check.txt',
            originalFiles.map((originalFile) => originalFile.fileName).join('\n'),
            {
                binary: true,
            },
        );

        zip.generateAsync({
            type: 'blob',
        }).then(
            (content) => callback(content),
        );
    };

    static base64ToBlob = (base64, name) => {
        let binaryString = window.atob(base64);
        let binaryLen    = binaryString.length;
        let arrayBuffer  = new ArrayBuffer(binaryLen);
        let unitArray    = new Uint8Array(arrayBuffer);

        for (let index = 0; index < binaryLen; index++) {
            unitArray[index] = binaryString.charCodeAt(index);
        }

        let blob              = new Blob([arrayBuffer]);
        blob.lastModifiedDate = new Date();
        blob.name             = name;

        return blob;
    };

    static getContent = (file, callback) => {
        const setFileName = file.name;
        const setName     = setFileName.slice(0, setFileName.lastIndexOf('.'));
        const fileOrders  = [];

        JSZip.loadAsync(file)
            .then((zip) => {
                const filePromises = [];

                Object.entries(zip.files).forEach(([name, value]) => {
                    const filePromise = new Promise((resolve) => {
                        const base64File    = Zip.arrayBufferToBase64(value._data.compressedContent);
                        const lowercaseName = name.toLowerCase();

                        if (lowercaseName.endsWith('.pdf')) {
                            FileUpload.saveFile(
                                base64File,
                                {
                                    name:         name,
                                    lastModified: value.date,
                                },
                                (savedFile) => resolve(savedFile),
                            );
                        } else if (lowercaseName.endsWith('.zip')) {
                            resolve(this.base64ToBlob(base64File, name));
                        } else if (lowercaseName === 'sonatis-export-check.txt') {
                            const fileNames = atob(base64File).split('\n');

                            fileNames.forEach((fileId) => {
                                fileOrders.push(fileId);
                            });

                            resolve();
                        } else {
                            resolve();
                        }
                    });

                    filePromises.push(filePromise);
                });

                Promise
                    .all(filePromises)
                    .then((results) => callback(setName, results, fileOrders))
                ;
            });
    };

    static zipSetFiles = (files, callback) => {
        FileStore.loadFilesFromStoreByIds(
            files,
            async (loadedFiles) => {
                Pdf
                    .base64ToPdf(loadedFiles)
                    .then((pdfBytesArray) => {
                        const pdfs = pdfBytesArray.map((pdfBytes) => {
                            if (pdfBytes) {
                                return {
                                    data:     pdfBytes.data,
                                    fileName: pdfBytes.file.fileName,
                                };
                            }

                            return null;
                        });

                        Zip.create(_.compact(pdfs), files, callback);
                    })
                ;
            },
        );
    };

    static arrayBufferToBase64 = (buffer) => {
        let binary  = '';
        const bytes = new Uint8Array(buffer);

        for (let index = 0; index < bytes.byteLength; index++) {
            binary += String.fromCharCode(bytes[index]);
        }

        return window.btoa(binary);
    };
}

export default Zip;
