//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }                   from 'redux-saga/effects';
import { call }                  from 'redux-saga/effects';
import { race }                  from 'redux-saga/effects';
import { take }                  from 'redux-saga/effects';
import { select }                from 'redux-saga/effects';
import { DropInActions }         from '@/store/actions/dropIn';
import moment                    from 'moment';
import { MessageOverlayActions } from '@/store/actions/messageOverlay';
import { SetActions }            from '@/store/actions/set';
import I18n                      from 'i18next';
import FileHelper                from '@/helper/File';
import Download                  from '@/helper/Download';
import Zip                       from '@/helper/Zip';
import _                         from 'lodash';
import { LoadingOverlayActions } from '@/store/actions/loadingOverlay';
import FileStore                 from '@/helper/FileStore';
import String                    from '@/helper/String';
import EpdConverter              from '@/helper/EpdConverter';
import BatterySonFile            from '@/assets/defaultZipFiles/Battery.son';
import LogoSonFile               from '@/assets/defaultZipFiles/Logo.son';
import MimeTypes                 from '@/constants/MimeTypes';
import ConvertTypes              from '@/constants/ConvertTypes';
import * as PDFJS                from 'pdfjs-dist';
import { ConfirmOverlayActions } from '@/store/actions/confirmOverlay';
import File                      from '@/helper/File';

const defaultFilesDefinition = [
    {
        name: 'Battery.son',
        file: BatterySonFile,
    },
    {
        name: 'Logo.son',
        file: LogoSonFile,
    },
];

const processFile = (file) => {
    file.fileName = file.name;
    file.name     = FileHelper.removeFileExtensionRemoveUnwantedCharactersAndTruncate(file.name);
    file.date     = moment(file.lastModified).format('L');

    delete file.lastModified;

    return file;
};

const getFilePagesCount = async (file) => {
    let pagesCount = 0;
    let type       = null;

    if (
        file.indexOf(`data:${MimeTypes.jpeg}`) === 0 ||
        file.indexOf(`data:${MimeTypes.jpg}`) === 0 ||
        file.indexOf(`data:${MimeTypes.png}`) === 0
    ) {
        type = ConvertTypes.image;

        ++pagesCount;
    } else if (file.indexOf(`data:${MimeTypes.pdf}`) === 0) {
        const pdf = await PDFJS.getDocument(file).promise;
        type      = ConvertTypes.pdf;

        pagesCount += pdf.numPages;
    }

    return { pagesCount, type };
};

const convertFileOrPdfToArrayOfEpdBuffer = function* (file, type) {
    const convertedPages = [];

    if (type === ConvertTypes.image) {
        const convertedPage = yield call(() => EpdConverter(file));

        convertedPages.push(convertedPage.buffer);
    } else if (type === ConvertTypes.pdf) {
        const pdf     = yield call(() => PDFJS.getDocument(file).promise);
        const canvas  = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.style  = 'border: 3px solid black';

        for (let i = 0; i < pdf.numPages; i++) {
            const startTime              = performance.now();
            const page                   = yield call(() => pdf.getPage(i + 1));
            const unscaledViewport       = page.getViewport({
                scale: 1,
            });
            const unscaledViewportWidth  = unscaledViewport.width || unscaledViewport.viewBox[2];
            const unscaledViewportHeight = unscaledViewport.height || unscaledViewport.viewBox[3];
            let scaledViewport           = null;

            if (unscaledViewportHeight > unscaledViewportWidth) {
                scaledViewport = page.getViewport({
                    scale: 1600 / unscaledViewport.height,
                });

                canvas.width  = scaledViewport.width;
                canvas.height = scaledViewport.height;
            } else {
                scaledViewport = page.getViewport({
                    scale: 1600 / unscaledViewport.width,
                });

                canvas.width  = scaledViewport.width;
                canvas.height = scaledViewport.height;
            }

            context.fillStyle = '#ffffff';

            context.fillRect(0, 0, canvas.width, canvas.height);

            yield call(() => page.render({
                canvasContext: context,
                viewport:      scaledViewport,
            }).promise);

            const convertedPage = yield call(
                () => new Promise((resolve) => {
                    canvas.toBlob(
                        (blob) => {
                            const reader = new FileReader();

                            reader.addEventListener('loadend', async () => {
                                const arrayBuffer = reader.result;
                                const epdData     = await EpdConverter(arrayBuffer);

                                resolve(epdData);
                            });
                            reader.readAsArrayBuffer(blob);
                        },
                        'image/png',
                    );
                }),
            );

            convertedPages.push(convertedPage.buffer);

            const endTime  = performance.now();
            const timeDiff = endTime - startTime;

            yield put(LoadingOverlayActions.preparationProgressed({
                timeNeededInSeconds: timeDiff / 1000,
            }));
        }
    }

    return convertedPages;
};

const startConvertProcess = function* (action, setName) {
    const { files } = action;

    let pagesCountSum    = 0;
    const defaultFiles   = [];
    const menuEntries    = [];
    const preparedFiles  = [];
    const convertedFiles = [];

    for (const element of defaultFilesDefinition) {
        const response = yield call(
            () => new Promise((resolve) => {
                resolve(fetch(element.file));
            }),
        );
        const content  = yield call(
            () => new Promise((resolve) => {
                resolve(response.blob());
            }),
        );
        element.file   = content;
    }

    for (let defaultFileIndex = 0; defaultFileIndex < defaultFilesDefinition.length; defaultFileIndex++) {
        const defaultFile = defaultFilesDefinition[defaultFileIndex];

        defaultFiles.push({
            name:     defaultFile.name,
            fileName: defaultFile.name,
            data:     defaultFile.file,
        });
    }

    // Count
    for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
        const file = files[fileIndex];

        const storeFile            = yield call(
            () => new Promise((resolve) => {
                resolve(FileStore.loadFile(file.fileId));
            }),
        );
        const { pagesCount, type } = yield call(
            () => new Promise((resolve) => {
                resolve(getFilePagesCount(storeFile.file));
            }),
        );

        preparedFiles.push({
            file:  storeFile,
            name:  file.name,
            type,
            pages: pagesCount,
        });

        pagesCountSum += pagesCount;
    }

    yield put(LoadingOverlayActions.preparationStarted({
        buildingMenu: false,
        fileCount:    pagesCountSum,
    }));

    // Convert
    for (let fileIndex = 0; fileIndex < preparedFiles.length; fileIndex++) {
        try {
            const storeFile            = preparedFiles[fileIndex];
            const { file, type, name } = storeFile;
            const epdFiles             = yield call(() => convertFileOrPdfToArrayOfEpdBuffer(file.file, type));
            const fileNumber           = fileIndex + 1;
            const paddedFileIndex      = `${fileNumber}`.padStart(4, 0);

            menuEntries.push({
                name,
                fileName:   `H${paddedFileIndex}.son`,
                isMainPage: true,
                data:       null,
            });

            for (let epdFileIndex = 0; epdFileIndex < epdFiles.length; epdFileIndex++) {
                const epdFile         = epdFiles[epdFileIndex];
                const paddedPageIndex = `${epdFileIndex + 1}`.padStart(4, 0);

                convertedFiles.push({
                    fileName: `N${paddedFileIndex}S${paddedPageIndex}.son`,
                    data:     epdFile,
                });
            }
        } catch (exception) {
            console.error('SetDetail convertFiles:', exception);
        }
    }

    yield put(LoadingOverlayActions.preparationStarted({
        buildingMenu: true,
        fileCount:    menuEntries.length,
    }));

    const fontSize           = 22;
    const menuEntryDistances = fontSize * 1.5;
    const maxEntries         = Math.floor((
        1600 - 250
    ) / menuEntryDistances);
    const font               = fontSize + 'px arial';

    for (let menuEntryIndex = 0; menuEntryIndex < menuEntries.length; menuEntryIndex++) {
        const startTime          = performance.now();
        const menuEntry          = menuEntries[menuEntryIndex];
        const canvas             = document.createElement('canvas');
        const pageCount          = Math.ceil(menuEntries.length / maxEntries);
        const page               = Math.ceil((
            menuEntryIndex + 1
        ) / maxEntries);
        const start              = Math.floor(menuEntryIndex / maxEntries) * maxEntries;
        const end                = start + maxEntries;
        const menuEntriesForPage = menuEntries.slice(start, end);
        const context            = canvas.getContext('2d');
        canvas.width             = 1600;
        canvas.height            = 1200;
        context.fillStyle        = 'white';

        context.fillRect(0, 0, canvas.width, canvas.height);

        context.fillStyle   = '#000000';
        context.strokeStyle = '#000000';

        for (let entryIndex = 0; entryIndex < menuEntriesForPage.length; entryIndex++) {
            const entry         = menuEntriesForPage[entryIndex];
            const maxNameLength = 70;
            let entryName       = String.truncate(entry.name, maxNameLength);
            const xPosition     = entryIndex * menuEntryDistances;
            context.font        = font;
            entryName           = (
                entryIndex + start + 1
            ) + '. ' + entryName;

            context.save();
            context.translate(150, canvas.height - 150);
            context.rotate(Math.PI / 2);
            context.fillText(`${page}/${pageCount}`, 0, 0);
            context.restore();
            context.save();
            context.translate(canvas.width - menuEntryDistances - xPosition, 100);
            context.rotate(Math.PI / 2);

            if (start + entryIndex === menuEntryIndex) {
                context.font = 'bold ' + font;
            } else {
                context.font = font;
            }

            context.fillText(entryName, 0, 80);
            context.restore();
        }

        // Use this to debug the menu canvas
        const debug = false;

        if (debug) {
            canvas.style.position = 'absolute';
            canvas.style.zIndex   = '999999';
            canvas.style.top      = '0';
            canvas.style.left     = '0';
            canvas.style.width    = '100%';
            canvas.style.height   = '100%';
            canvas.style.display  = 'none';

            document.body.appendChild(canvas);
        }

        const convertedPage = yield call(
            () => new Promise((resolve) => {
                canvas.toBlob(
                    (blob) => {
                        const reader = new FileReader();

                        reader.addEventListener('loadend', async () => {
                            const arrayBuffer = reader.result;
                            const epdData     = await EpdConverter(arrayBuffer);

                            resolve(epdData);
                        });
                        reader.readAsArrayBuffer(blob);
                    },
                    'image/png',
                );
            }),
        );

        convertedFiles.push({
            fileName: menuEntry.fileName,
            data:     convertedPage.buffer,
        });

        const endTime  = performance.now();
        const timeDiff = endTime - startTime;

        yield put(LoadingOverlayActions.preparationProgressed({
            timeNeededInSeconds: timeDiff / 1000,
        }));
    }

    const allFiles = [...convertedFiles, ...defaultFiles];

    console.log('Create zip from ' + files.length + ' epd files');

    return yield call(
        () => new Promise((resolve) => {
            Zip.create(allFiles, [], resolve);
        }),
    );
};

const convertSet = function* (action) {
    const activeSetIndex = yield select(state => state.set.activeSetIndex);
    const activeSet      = yield select(state => state.set.sets[activeSetIndex]);
    const setName        = activeSet.name;

    const { result } = yield race({
        result: call(() => startConvertProcess(action, setName)),
        abort:  take(SetActions.abortDownload().type),
    });

    yield put(LoadingOverlayActions.preparationFinished());

    if (result) {
        Download.downloadFile(result, `${setName}.zip`);
    }
};

const tryAddPdfFiles = function* (files, activeSet) {
    const existingFiles     = [];
    const newProcessedFiles = [];

    for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
        const unprocessedFile = files[fileIndex];
        const processedFile   = processFile(unprocessedFile);
        let alreadyExists     = false;

        for (const activeSetFile of activeSet.files) {
            if (activeSetFile.fileName === processedFile.fileName) {
                alreadyExists = true;

                break;
            }
        }

        if (alreadyExists) {
            existingFiles.push(processedFile);
        } else {
            newProcessedFiles.push(processedFile);
        }
    }

    yield put(DropInActions.closeOverlay());

    if (newProcessedFiles.length > 0) {
        yield put(SetActions.addFiles({
            files: newProcessedFiles,
        }));
    }

    if (existingFiles.length > 0) {
        const names = existingFiles.map((existingFile) => existingFile.fileName);
        let text    = I18n.t('followingFilesAlreadyExist', {
            names: names.join(', '),
        });

        if (names.length > 10) {
            text = I18n.t('followingFilesAlreadyExistMany', {
                count: names.length,
            });
        }

        yield put(MessageOverlayActions.setTextAndTitle({
            title: I18n.t('couldNotAddAllFilesToSet'),
            text,
        }));
        yield put(MessageOverlayActions.openOverlay());
    }
};

const tryAddZipFiles = function* (files, createNewSet = false) {
    yield put(LoadingOverlayActions.uploadStarted());

    for (const file of files) {
        try {
            const { setName, files: extractedFiles, fileOrders } = yield new Promise((resolve, reject) => {
                Zip.getContent(file, (setName, files, fileOrders) => {
                    if (fileOrders) {
                        resolve({
                            setName,
                            files,
                            fileOrders,
                        });
                    } else {
                        reject();
                    }
                });
            });

            const { pdf: pdfFiles, zip: zipFiles } = File.filterFileTypes(extractedFiles);
            const orderedPdfFiles                  = [];

            fileOrders.forEach((fileOrder) => {
                const nextFile = pdfFiles.find((pdfFile) => pdfFile.name === fileOrder);

                if (nextFile) {
                    orderedPdfFiles.push(nextFile);
                }
            });

            if (orderedPdfFiles.length) {
                if (createNewSet) {
                    yield put(SetActions.newSet({
                        name: setName,
                    }));
                }

                yield put(SetActions.tryAddFiles({
                    pdfFiles: orderedPdfFiles,
                    zipFiles: [],
                }));
            }

            if (zipFiles.length) {
                yield put(SetActions.tryAddFiles({
                    pdfFiles: [],
                    zipFiles,
                }));
            }
        } catch (error) {
            yield put(MessageOverlayActions.setTextAndTitle({
                title: I18n.t('zipNotCreatedBySonatis'),
                text:  I18n.t('zipNotCreatedBySonatisText'),
                hint:  I18n.t('zipNotCreatedBySonatisHint'),
            }));
            yield put(MessageOverlayActions.openOverlay());
        }
    }

    yield put(LoadingOverlayActions.uploadFinished());
};

// This may fail when the user tries to add a file that already exists with the same name
const tryAddFiles = function* (action) {
    const activeSetIndex = yield select(state => state.set.activeSetIndex);
    const existingSets   = _.cloneDeep(yield select(state => state.set.sets));
    const activeSet      = existingSets[activeSetIndex];
    const pdfFiles       = action.pdfFiles;
    const zipFiles       = action.zipFiles;

    if (pdfFiles.length) {
        if (!activeSet) {
            yield put(SetActions.newSet({
                name: null,
            }));

            return yield(
                tryAddFiles(action)
            );
        }

        yield(
            tryAddPdfFiles(pdfFiles, activeSet)
        );
    }

    if (zipFiles.length) {
        let isNewSet = true;

        if (activeSet) {
            yield put(ConfirmOverlayActions.setTextAndTitle({
                title: I18n.t('importIntoExistingSet'),
                text:  I18n.t('shouldTheZipBeImportedIntoTheExistingSet'),
            }));
            yield put(ConfirmOverlayActions.openOverlay());

            const { newSet } = yield race({
                addToActive: take(ConfirmOverlayActions.importIntoActiveSet().type),
                newSet:      take(ConfirmOverlayActions.importIntoNewSet().type),
            });
            isNewSet         = !!newSet;
        } else {
            yield put(ConfirmOverlayActions.importIntoNewSet());
        }

        yield(
            tryAddZipFiles(zipFiles, !!isNewSet)
        );
    }
};

const downloadSet = function* () {
    const activeSetIndex = yield select(state => state.set.activeSetIndex);
    const set            = _.cloneDeep(yield select(state => state.set.sets[activeSetIndex]));

    if (set.files.length) {
        yield put(LoadingOverlayActions.preDownloadStarted());

        const { result } = yield race({
            result: call(
                () => new Promise((resolve) => {
                    Zip.zipSetFiles(
                        set.files,
                        resolve,
                    );
                }),
            ),
            abort:  take(SetActions.abortDownload().type),
        });

        yield put(LoadingOverlayActions.preparationFinished());

        if (result) {
            Download.downloadFile(result, `${set.name}.zip`);
        }
    }
};

const downloadSets = function* () {
    const setNames     = [];
    const sets         = _.cloneDeep(yield select(state => state.set.sets));
    const filteredSets = sets.filter((set) => set.files.length);

    yield put(LoadingOverlayActions.preDownloadStarted());

    const zipPromises = filteredSets.map((set) => {
        return new Promise((resolve) => {
            Zip.zipSetFiles(set.files, (zip) => {
                let setName = set.name;

                if (setNames.includes(setName)) {
                    setName = `${setName} 1`;
                }

                setNames.push(setName);

                resolve({
                    data:     zip,
                    fileName: `${setName}.zip`,
                });
            });
        });
    });

    const date       = moment();
    const { result } = yield race({
        result: call(
            () => new Promise((resolve) => {
                Promise.all(zipPromises).then((blobs) => {
                    Zip.create(
                        blobs,
                        filteredSets,
                        resolve,
                    );
                });
            }),
        ),
        abort:  take(SetActions.abortDownload().type),
    });

    yield put(LoadingOverlayActions.preparationFinished());

    if (result) {
        Download.downloadFile(result, `sets-${date.format('YYYYMMDD')}.zip`);
    }
};

export default {
    convertSet,
    downloadSet,
    downloadSets,
    tryAddFiles,
};
