//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import PropTypes              from '@/components/PropTypes';
import React                  from 'react';
import { connect }            from 'react-redux';
import ComponentHelper        from '@/helper/ComponentHelper';
import StatelessBreadCrumbBar from '@/components/stateless/atomic/BreadCrumbBar';

class BreadCrumbBar extends React.Component {
    render () {
        return (
            <StatelessBreadCrumbBar pathname={this.props.pathname} />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = BreadCrumbBar;

Component.propTypes = {
    pathname: PropTypes.string,
};

Component.defaultProps = {
    pathname: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        pathname: state.router.location.pathname,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
