//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { Component } from 'react';
import styles        from '@/styles.module.scss';
import TextContainer from '@/components/stateless/atomic/TextContainer';

class Screen extends Component {
    render() {
        return (
            <div className={styles.textPage}>
                <TextContainer autoStyleChildren={true}>
                    <h1>
                        Datenschutz
                    </h1>
                    <p>
                        Datenschutz und Datensicherheit für die Kunden und Partner unseres Unternehmens wie auch von Interessenten und Nutzern unseres Webauftrittes haben in unserem Unternehmen einen hohen Stellenwert. Transparenz bezüglich der Verarbeitung Ihrer personenbezogenen Daten wie auch der
                        Schutz Ihrer Daten sind uns daher besonders wichtig. Mit dieser Erklärung geben wir Ihnen einen Überblick, wie bei der Nutzung unserer Internetseiten Daten zu Ihrer Person erhoben und verarbeitet werden und was Sie ggf. selbst zum besseren Schutz Ihrer Daten tun können.
                    </p>
                    <h2>
                        Verantwortlicher für die Verarbeitung
                    </h2>
                    <p>
                        Oliver Strottner
                        <br />
                        Lübecker Str. 18
                        <br />
                        88250 Weingarten
                        <br />
                        Tel.: 0751 56 99 59 77
                    </p>
                    <h2>
                        Datenschutzbeauftragter des Unternehmens
                    </h2>
                    <p>
                        Oliver Strottner
                        <br />
                        Lübecker Str. 18
                        <br />
                        88250 Weingarten
                        <br />
                        Tel.: 0751 56 99 59 77
                    </p>
                    <h2>
                        Was sind personenbezogene Daten
                    </h2>
                    <p>
                        Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Entscheidend ist also, ob durch die erhobenen Daten ein Personenbezug hergestellt werden kann. Darunter fallen Informationen wie z.B. Ihr Name,
                        Ihre Anschrift, Ihre Telefonnummer, Mailadresse. Informationen, die nicht direkt mit Ihrer wirklichen Identität in Verbindung gebracht werden – wie z.B. favorisierte Webseiten oder Anzahl der Nutzer einer Seite – sind keine personenbezogenen Daten.
                    </p>
                    <h2>
                        Wie erheben und verarbeiten wir Daten zu Ihrer Person
                    </h2>
                    <p>
                        Wenn Sie unsere Webseiten besuchen, speichern unsere Webserver standardmäßig zum Zweck der Systemsicherheit temporär die Verbindungsdaten des anfragenden Rechners, die Webseiten, die Sie bei uns besuchen, das Datum und die Dauer des Besuches, die Erkennungsdaten des
                        verwendeten
                        Browser- und Betriebssystem-Typs sowie die Webseite, von der aus Sie uns besuchen. Darüber hinausgehende personenbezogene Angaben wie Ihr Name, Ihre Anschrift, Telefonnummer oder E-Mail-Adresse werden nicht erfasst, es sei denn, diese Angaben werden von Ihnen freiwillig
                        gemacht,
                        z.B. im Rahmen einer Registrierung, einer Umfrage, eines Preisausschreibens, zur Durchführung eines Vertrages oder einer Informationsanfrage.
                    </p>
                    <h2>
                        Umfang der Verarbeitung personenbezogener Daten
                    </h2>
                    <p>
                        Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (E-Mail-Adressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. E-Mails werden über ein
                        Kontaktformular übermittelt. Wenn Sie uns eine solche Nachricht zuschicken, werden Ihre persönlichen Daten nur insoweit gesammelt, als es für eine Antwort erforderlich ist. Die E-Mail wird unverschlüsselt übermittelt.
                    </p>
                    <p>
                        Die von Ihnen zur Verfügung gestellten personenbezogenen Daten verwenden wir ausschließlich zum Zweck der technischen Administration der Webseiten und zur Erfüllung Ihrer Wünsche und Anforderungen, also in der Regel zur Abwicklung des mit Ihnen geschlossenen Vertrages oder
                        zur
                        Beantwortung Ihrer Anfrage. Nur soweit Sie uns zuvor Ihre Einwilligung erteilt haben bzw. wenn Sie – soweit gesetzliche Regelungen dies vorsehen – keinen Widerspruch eingelegt haben, nutzen wir diese Daten auch für produktbezogene Umfragen, Marketingzwecke und statistische
                        Zwecke.
                    </p>
                    <p>
                        Eine Weitergabe, Verkauf oder sonstige Übermittlung Ihrer personenbezogenen Daten an Dritte erfolgt nicht, es sei denn, dass dies zum Zwecke der Vertragsabwicklung erforderlich ist oder Sie ausdrücklich eingewilligt haben. Eine erteilte Einwilligung kann mit Wirkung für die
                        Zukunft jederzeit widerrufen werden.
                    </p>
                    <h2>
                        Wie lange bleiben Ihre Daten gespeichert
                    </h2>
                    <p>
                        Grundsätzlich speichern wir alle Informationen, die Sie uns übermitteln, bis der jeweilige, z.B. vertragliche Zweck erfüllt ist. Z.B. bei Anfragen bis zur Erledigung, bei Newslettern bis Sie den Newsletter wieder abbestellen. Ist eine längere Speicherung gesetzlich
                        vorgesehen,
                        erfolgt die Speicherung in diesem Rahmen. Sollten Sie nicht mehr wünschen, dass wir Ihre Daten nutzen, so kommen wir dieser Bitte natürlich umgehend nach (Bitte wenden Sie sich dazu an die unter „Kontakt“ angegebene Adresse).
                    </p>
                    <h2>
                        Wann werden Ihre Daten gelöscht
                    </h2>
                    <p>
                        Die Löschung der gespeicherten personenbezogenen Daten erfolgt, wenn Sie ihre Einwilligung zur Speicherung widerrufen, wenn die Kenntnis der Daten zur Erfüllung des mit der Speicherung verfolgten Zwecks nicht mehr erforderlich ist oder wenn die Speicherung aus sonstigen
                        gesetzlichen Gründen unzulässig ist. Daten für Abrechnungszwecke und buchhalterische Zwecke werden von einem Löschungsverlangen nicht berührt.
                    </p>
                    <h2>
                        Einsatz von Cookies
                    </h2>
                    <p>
                        Im Rahmen Ihres Besuchs auf unseren Seiten verwenden wir sogenannte Cookies. Das sind kleine Textdateien, die auf Ihrem Computer abgelegt werden. Cookies helfen uns dabei, die Nutzungshäufigkeit und die Anzahl der Nutzer unserer Internetseiten zu ermitteln, sowie unsere
                        Angebote
                        für Sie möglichst komfortabel und effizient zu gestalten.
                    </p>
                    <p>
                        Wir verwenden einerseits sogenannte „Session-Cookies“, die ausschließlich für die Dauer Ihrer Nutzung einer unserer Internetseiten zwischengespeichert werden. Zum anderen benutzen wir „permanente Cookies“, um Informationen über Besucher festzuhalten, die wiederholt auf eine
                        unserer Interseiten zugreifen. Der Zweck des Einsatzes dieser Cookies besteht darin, Ihnen eine optimale Benutzerführung anbieten zu können sowie Sie „wiederzuerkennen“ und Ihnen bei wiederholter Nutzung eine möglichst abwechslungsreiche Internetseite und neue Inhalte
                        präsentieren zu können. Der Inhalt eines permanenten Cookies beschränkt sich auf eine Identifikationsnummer. Name, IP-Adresse etc. werden nicht gespeichert. Eine Einzelprofilbildung über Ihr Nutzungsverhalten findet nicht statt.
                    </p>
                    <p>
                        Eine Nutzung unserer Angebote ist auch ohne Cookies möglich. Sie können in Ihrem Browser das Speichern von Cookies deaktivieren, auf bestimmte Websites beschränken oder Ihren Browser so einstellen, dass er sie benachrichtigt, sobald ein Cookie gesendet wird. Bitte beachten
                        Sie
                        aber, dass Sie bei einer Deaktivierung mit einer eingeschränkten Darstellung der Seite und mit einer eingeschränkten Benutzerführung rechnen müssen. Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen
                        erwünschter
                        Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste.
                        Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.
                    </p>
                    <h2>
                        Was tun wir für die Sicherheit der Verarbeitung
                    </h2>
                    <p>
                        Unser Unternehmen trifft alle notwendigen technischen und organisatorischen Sicherheitsmaßnahmen, um Ihre personenbezogenen Daten vor Verlust und Missbrauch zu schützen. So werden Ihre Daten in einer sicheren Betriebsumgebung gespeichert, die der Öffentlichkeit nicht
                        zugänglich
                        ist. In bestimmten Fällen werden Ihre personenbezogenen Daten bei der Übermittlung durch die sog. Secure Socket Layer-Technologie (SSL) verschlüsselt. Das bedeutet, dass die Kommunikation zwischen Ihrem Computer und den Servern unseres Unternehmens unter Einsatz eines
                        anerkannten
                        Verschlüsselungsverfahrens erfolgt, wenn Ihr Browser SSL unterstützt.
                    </p>
                    <p>
                        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass
                        die
                        Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                    </p>
                    <p>
                        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden. Sollten Sie mit unserem Unternehmen über E-Mail in Kontakt treten wollen, weisen wir darauf hin, dass die Vertraulichkeit der übermittelten
                        Informationen nicht gewährleistet ist. Der Inhalt von E-Mails kann von Dritten eingesehen werden. Wir empfehlen Ihnen daher, uns vertrauliche Informationen ausschließlich über den Postweg zukommen zu lassen.
                    </p>
                    <h2>
                        Das sind Ihre Datenschutzrechte
                    </h2>
                    <p>
                        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und mögliche Empfänger und den Zweck der Datenverarbeitung (Art. 15 DSGVO) und ggf. ein Recht auf
                        Berichtigung unrichtiger Daten Art. 16 DSGVO), Löschung dieser Daten (Art. 17 DSGVO) das Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO, auf Widerspruch (Art. 21 DSGVO) sowie das Recht auf Datenübertragbarkeit von Ihnen bereitgestellter Daten nach Art. 20 DSGVO).
                        Beim Auskunftsrecht und beim Löschungsrecht gelten die Einschränkungen nach §§ 34 und 35 BDSG.
                    </p>
                    <p>
                        Darüber hinaus steht Ihnen im Falle datenschutzrechtlicher Verstöße ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu (Art. 77 DSGVO i.V.m. §19 BDSG). Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes,
                        in
                        dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
                    </p>
                    <p>
                        Wie Sie gegebene Einwilligungen zur Datenverarbeitung widerrufen können
                        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                        Datenverarbeitung
                        bleibt vom Widerruf unberührt.
                    </p>
                    <h2>
                        Änderungen der Datenschutzerklärung
                    </h2>
                    <p>
                        Es können Änderungen an diesen Datenschutzhinweisen vorgenommen werden, die auf dieser Seite rechtzeitig bekanntgegeben werden.
                    </p>
                </TextContainer>
            </div>
        );
    }
}

export default Screen;
