//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import '@/language/Language';
import '@babel/polyfill';
import React                 from 'react';
import * as serviceWorker    from './serviceWorker';
import CacheInvalidator      from '@/components/stateless/atomic/CacheInvalidator';
import Dashboard             from '@/screens/Dashboard';
import FileStorage           from '@/helper/FileStore';
import Header                from '@/components/stateless/composed/Header';
import I18n                  from 'i18next';
import Imprint               from '@/screens/Imprint';
import moment                from 'moment';
import NotFound              from '@/screens/NotFound';
import Privacy               from '@/screens/Privacy';
import ReactDOM              from 'react-dom';
import Routes                from '@/constants/Routes';
import ScreenDesign          from '@/screens/ScreenDesign';
import ScreenTooSmallOverlay from './components/stateless/atomic/ScreenTooSmallOverlay';
import store                 from '@/store';
import styles                from './styles.module.scss';
import { ConnectedRouter }   from 'connected-react-router';
import { history }           from '@/store';
import { Provider }          from 'react-redux';
import { Route }             from 'react-router';
import { Switch }            from 'react-router';

import 'moment/locale/de.js';
import '@/store/miscellaneous/immutability-helper-extensions';

FileStorage.initializeStore();
moment.locale(I18n.language);

ReactDOM.render(
    <Provider store={store()}>
        <CacheInvalidator />
        {/*
            If you don't want to automatically clear the browsers cache and update the
            application you can switch <CacheInvalidator /> by <CacheInvalidatorWithPrompt />
            to ask the user if he wants to update.
        */}
        <ConnectedRouter history={history}>
            <ScreenTooSmallOverlay />
            <div className={styles.appContent}>
                <Header />
                <Switch>
                    <Route
                        exact
                        path={Routes.dashboard}
                        component={Dashboard}
                    />
                    <Route
                        exact
                        path={Routes.imprint}
                        component={Imprint}
                    />
                    <Route
                        exact
                        path={Routes.privacy}
                        component={Privacy}
                    />
                    <Route
                        exact={false}
                        path={Routes.screenDesign}
                        component={ScreenDesign}
                    />
                    <Route component={NotFound} />
                </Switch>
            </div>
        </ConnectedRouter>
    </Provider>
    , document.getElementById('root'),
);

serviceWorker.register();
