//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import * as PropTypes         from 'prop-types';
import _                      from 'lodash';
import ComponentHelper        from '@/helper/ComponentHelper';
import StatelessDropIn        from '@/components/stateless/atomic/DropIn';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { DropInActions }      from '@/store/actions/dropIn';
import { SetActions }         from '@/store/actions/set';

class DropIn extends React.Component {
    render() {
        return (
            <StatelessDropIn
                dropInOpen={this.props.dropInOpen}
                loadingFilesDone={this.loadingFilesDone}
                closeDropInClicked={this.closeDropInClicked}
                isSetActive={this.props.isSetActive}
            />
        );
    }

    loadingFilesDone = (pdfFiles, zipFiles) => {
        this.props.tryAddFiles({
            pdfFiles,
            zipFiles
        });
    };

    closeDropInClicked = () => {
        this.props.closeOverlay();
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = DropIn;

Component.propTypes = {
    dropInOpen:  PropTypes.bool,
    isSetActive: PropTypes.bool,
};

Component.defaultProps = {
    dropInOpen:  false,
    isSetActive: false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        dropInOpen:  state.dropIn.dropInOpen,
        isSetActive: !!state.set.sets[state.set.activeSetIndex],
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    DropInActions,
    SetActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
