//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes  from 'prop-types';
import _               from 'lodash';
import ComponentHelper from '@/helper/ComponentHelper';
import I18n            from 'i18next';
import IconButton      from '@/components/stateless/atomic/IconButton';
import IconButtonSize  from '@/components/stateless/atomic/IconButton/IconButtonSize';
import IconButtonTheme from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import React           from 'react';
import styles          from './styles.module.scss';
import DebounceInput   from 'react-debounce-input';

class SetFiles extends React.Component {
    nameInputDebounce = 50;

    constructor (props) {
        super(props);

        this.state = {
            deleteSetFile: false,
        };
    }

    render () {
        return this.props.files.map(this.renderFileContainer);
    }

    renderFileContainer = (file, index, files) => {
        const isFirstFile = index === 0;
        const isLastFile  = files.length === index + 1;

        return (
            <div
                className={styles.setFile}
                key={'file-' + index}
            >
                {this.renderFile(file, index, isFirstFile, isLastFile)}
            </div>
        );
    };

    renderFile = (file, index, isFirstFile, isLastFile) => {
        if (this.state.deleteSetFile === index) {
            return this.renderDeleteBox(file, index);
        }

        return this.renderFileBox(file, index, isFirstFile, isLastFile);
    };

    renderFileBox = (file, index, isFirstFile, isLastFile) => {
        const humanReadableIndex = index + 1;

        return (
            <>
                <span>
                    {humanReadableIndex}
                </span>
                <DebounceInput
                    value={file.name}
                    debounceTimeout={this.nameInputDebounce}
                    onChange={(event) => this.onNameChange(event, index)}
                />
                <div className={styles.fileButtonContainer}>
                    <div className={styles.fileArrowContainer}>
                        <div className={styles.fileArrowUp}>
                            <IconButton
                                iconType={IconType.chevron}
                                size={IconButtonSize.small}
                                onClick={() => this.props.onMoveFileUpClicked({ fileIndex: index })}
                                disabled={isFirstFile}
                            />
                        </div>
                        <div className={styles.fileArrowDown}>
                            <IconButton
                                iconType={IconType.chevron}
                                size={IconButtonSize.small}
                                onClick={() => this.props.onMoveFileDownClicked({ fileIndex: index })}
                                disabled={isLastFile}
                            />
                        </div>
                    </div>
                    <div className={styles.fileDeleteContainer}>
                        <IconButton
                            iconType={IconType.minusCircle}
                            onClick={() => this.setDeleteSetFile(index)}
                        />
                    </div>
                </div>
                <div className={styles.fileInformation}>
                    <span>
                        {file.fileName}
                    </span>
                    <span>
                        {file.date}
                    </span>
                </div>
            </>
        );
    };

    renderDeleteBox = () => {
        return (
            <div className={styles.deleteContainer}>
                <div className={styles.deleteContainerContent}>
                    <span>
                        {I18n.t('deleteFileCheck')}
                    </span>
                    <div className={styles.deleteButtonContainer}>
                        <IconButton
                            iconType={IconType.minusCircle}
                            text={I18n.t('deleteFile')}
                            theme={IconButtonTheme.red}
                            onClick={this.deleteSetFileConfirmClick}
                        />
                        <IconButton
                            iconType={IconType.plusCircle}
                            text={I18n.t('abort')}
                            theme={IconButtonTheme.transparent}
                            onClick={this.unsetDeleteSetFile}
                        />
                    </div>
                </div>
            </div>
        );
    };

    onNameChange = (event, fileIndex) => {
        this.props.onNameChange({
            name:      event.target.value,
            fileIndex: fileIndex,
        });
    };

    deleteSetFileConfirmClick = () => {
        this.setState({ deleteSetFile: false });

        this.props.onDeleteSetFileClicked({ fileIndex: this.state.deleteSetFile });
    };

    setDeleteSetFile = (index) => {
        this.setState({ deleteSetFile: index });

        document.addEventListener('mousedown', this.handleSecondDeleteClick, false);
    };

    unsetDeleteSetFile = () => {
        this.setState({ deleteSetFile: false });

        document.removeEventListener('mousedown', this.handleSecondDeleteClick, false);
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SetFiles;

Component.propTypes = {
    files:                  PropTypes.array,
    onDeleteSetFileClicked: PropTypes.func,
    onMoveFileDownClicked:  PropTypes.func,
    onMoveFileUpClicked:    PropTypes.func,
    onNameChange:           PropTypes.func,
};

Component.defaultProps = {
    files:                  [],
    onDeleteSetFileClicked: _.noop,
    onMoveFileDownClicked:  _.noop,
    onMoveFileUpClicked:    _.noop,
    onNameChange:           _.noop,
};

Component.renderAffectingProps = [
    'files',
];

Component.renderAffectingStates = [
    'deleteSetFile',
];

export default Component;
