//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _           from 'lodash';
import FileStorage from '@/helper/FileStore';

class FileUpload {
    static addFiles = async (uploadedFiles) => {
        const fileNames = Object.keys(uploadedFiles);
        const files     = [];

        for (let fileNameIndex = 0; fileNameIndex < fileNames.length; fileNameIndex++) {
            const key  = fileNames[fileNameIndex];
            const file = await this.processUploadedFile(key, uploadedFiles);

            files.push(file);
        }

        return files;
    };

    static processUploadedFile = (key, uploadedFiles) => {
        return new Promise((resolve) => {
            const reader       = new FileReader();
            const uploadedFile = uploadedFiles[key];

            reader.onloadend = () => {
                this.onFileLoadEnd(reader.result, uploadedFile, (file) => {
                    resolve(file);
                });
            };

            reader.readAsDataURL(uploadedFile);
        });
    };

    static onFileLoadEnd = (result, uploadedFile, callback) => {
        const fileInformation = this.getFileInformation(uploadedFile);

        this.saveFile(result, fileInformation, callback);
    };

    static getFileInformation = (uploadedFile) => {
        return Object.assign(
            {},
            {
                name:         uploadedFile.name,
                lastModified: uploadedFile.lastModified,
            },
        );
    };

    static saveFile = (result, fileInformation, callback) => {
        FileStorage.saveFile(result).then(
            (id) => {
                fileInformation.fileId = id;

                this.onLoadingFileComplete(fileInformation, callback);
            },
            (error) => {
                console.log('FileUpload: saveFile ', error);
            },
        );
    };

    static onLoadingFileComplete = (file, callback) => {
        callback(_.cloneDeep(file));
    };
}

export default FileUpload;
