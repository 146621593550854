//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as PropTypes  from 'prop-types';
import ComponentHelper from '@/helper/ComponentHelper';
import React           from 'react';
import StatelessSet    from '@/components/stateless/composed/Set';
import { connect }     from 'react-redux';

class Set extends React.Component {
    render () {
        return (
            <StatelessSet
                set={this.props.set}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Set;

Component.propTypes = {
    activeSetIndex: PropTypes.number,
    set:            PropTypes.object,
};

Component.defaultProps = {
    activeSetIndex: null,
    set:            {},
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        activeSetIndex: state.set.activeSetIndex,
        set:            state.set.sets[state.set.activeSetIndex],
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
