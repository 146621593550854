//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import String from '@/helper/String';
import _      from 'lodash';

const imageFileExtensions = [
    'gif',
    'jpg',
    'jpeg',
    'png',
    'webp',
];

class File {
    static pathIsImage = (path) => {
        for (const imageFileExtension of imageFileExtensions) {
            const imageFileExtensionLowerCase = imageFileExtension.toLowerCase();

            if (path.indexOf(imageFileExtensionLowerCase) > -1) {
                return true;
            }
        }

        return false;
    };

    static removeFileExtensionRemoveUnwantedCharactersAndTruncate = (name) => {
        const nameWithoutFileExtension = name
            .split('.')
            .slice(0, -1)
            .join('.')
        ;

        return File.removeUnwantedCharactersAndTruncate(nameWithoutFileExtension);
    };

    static removeUnwantedCharactersAndTruncate = (string) => {
        const cleaned   = string
            .replace(/[^\p{Letter}\p{Mark}0-9]/gu, ' ')
            .replace(/\s\s+/g, ' ')
        ;
        const truncated = String.truncate(cleaned, 70);

        return truncated;
    };

    static filterFileTypes = (files, types = ['pdf', 'zip']) => {
        const filteredTypes = {};

        types.forEach((type) => {
            filteredTypes[type] = _.filter(files, (file) => file && file.name.toLowerCase().endsWith(`.${type}`));
        });

        return filteredTypes;
    };
}

export default File;
