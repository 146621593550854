//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { Component }   from 'react';
import I18n            from 'i18next';
import Link            from '@/components/stateless/atomic/Link';
import PageTitleHelper from '@/helper/PageTitle';
import Routes          from '@/constants/Routes';
import styles          from './styles.module.scss';
import { Helmet }      from 'react-helmet';

class Screen extends Component {
    render () {
        return (
            <div className={styles.fullScreen}>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('pageNotFound'))}
                    </title>
                </Helmet>
                <div className={styles.content}>
                    <h1>
                        {I18n.t('error_404')}
                    </h1>
                    <p>
                        {I18n.t('pageNotFound')}
                    </p>
                    <Link
                        to={Routes.dashboard}
                        className={styles.backButton}
                    >
                        {I18n.t('goToDashboard')}
                    </Link>
                </div>
            </div>
        );
    }
}

export default Screen;
